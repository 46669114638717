/* ---------------- Breadcrumbs ---------------- */

.breadcrumbs {

    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    padding-bottom: 21px;
    padding-top: 30px;



    $shift: 8px;

    letter-spacing: 1.05px;

    &__item {
        display: flex;
        align-items: center;
        margin-left: $shift;
    }

    &__item:after {
        content: '/';
        margin-left: $shift;
    }

    &__item:last-child {
        // margin-left: 0;
        opacity: 0.5;
    }

    &__item:last-child:after {
        display: none;
    }

    &__link {
        font-family: $font-r;
        font-size: 15px;
        color: $c-default;
    }
}



@media (max-width: 990px) {

    .breadcrumbs {
        height: 79px;

        &__link {
            font-size: 15px;
        }
    }
}

@media (max-width: 620px) {

    .breadcrumbs {
        height: 57px;
        margin-top: 0;
        margin-bottom: 0;
        padding: 0;

        &__link {
            font-size: 14px;
        }
    }
}
