@charset "UTF-8";
/* --- Imports --- */
/* --- Fonts --- */
/* --- Colors --- */
/* --- Basic ---*/
html, body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
}

div, p, span, a, header, footer, main, article, section,
form, input, textarea, select, button, ul, ol, li, i {
  box-sizing: border-box;
}

ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

ol {
  list-style-type: decimal;
  padding: 0;
  margin: 0;
}

img {
  border: 0;
}

a {
  text-decoration: none;
}

a:hover {
  text-decoration: none;
}

input {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

select, textarea {
  outline: 0;
}

button {
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0;
  cursor: pointer;
  overflow: visible;
}

input[type=submit], input[type=text], input[type=password], textarea {
  -webkit-appearance: none;
}

button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

p {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-weight: normal;
}

main {
  display: block;
}

/* ---------------- Basic elements ---------------- */
:root {
  touch-action: pan-x pan-y;
  height: 100%;
}

.no_scroll {
  overflow: hidden;
}

body {
  min-width: 970px;
  font-family: "interregular", sans-serif;
  font-size: 14px;
  color: #151515;
  line-height: 1.45;
}

.s-main {
  min-height: 300px;
  position: relative;
}
.s-main__wrapper {
  margin-top: 15px;
  padding-left: 240px;
  position: relative;
}
.s-main__main-column {
  float: left;
  width: 100%;
  min-height: 100px;
}
.s-main__left-column {
  float: left;
  width: 240px;
  margin-left: -240px;
  position: relative;
  left: -100%;
}
.s-main__wrapper:after {
  display: block;
  clear: both;
  content: "";
}

.s-main--no-margin {
  margin-top: 0 !important;
}

.container {
  width: 100%;
  max-width: 1330px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
}

.container--carousel {
  padding-left: 5px;
  padding-right: 5px;
}

.padding {
  padding-left: 20px;
  padding-right: 20px;
}

.hidden {
  display: none !important;
}

.visible {
  display: block !important;
}

.text-center {
  text-align: center !important;
}

h1, h2, h3 {
  display: flex;
  justify-content: center;
}

.v-center {
  display: flex;
  height: 100%;
  align-items: center;
}

.line {
  height: 1px;
  border: 0;
  margin-top: 16px;
  margin-bottom: 16px;
  background: #151515;
}

/* ---------------- Titles ---------------- */
.title {
  display: flex;
  justify-content: flex-start;
  padding-bottom: 7px;
  margin-top: 10px;
  margin-bottom: 15px;
  position: relative;
  font-family: "interregular", sans-serif;
  font-size: 24px;
  color: #151515;
  line-height: 1.3;
  text-transform: uppercase;
}
.title:after {
  content: "";
  position: absolute;
  height: 1px;
  width: 90px;
  background-color: red;
  left: 0;
  bottom: 0;
}

/* ---------------- Misc ---------------- */
.mt-0 {
  margin-top: 0;
}

.mt-12 {
  margin-top: 12px;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 6px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 16px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

/* ---------------- Buttons ---------------- */
.button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 126px;
  height: 42px;
  position: relative;
  border: 1px solid black;
  border-radius: 25px;
  font-family: "interregular", sans-serif;
  font-size: 14px;
  color: black;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  text-align: center;
  cursor: pointer;
}
.button:hover {
  background: gray;
  color: white;
  text-decoration: none;
}
.button:hover .button__svg {
  fill: white;
}

/* ---------------- Form elements ---------------- */
.label {
  display: block;
  font-family: "interregular", sans-serif;
  font-size: 15px;
  color: #151515;
  letter-spacing: 0.5px;
}

.required:after {
  position: relative;
  left: 2px;
  color: #151515;
  content: "* ";
  font-weight: bold;
}

.input-wrapper {
  position: relative;
  overflow: hidden;
}

.input {
  display: block;
  width: 100%;
  height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ebebeb;
  border-radius: 25px;
  font-family: "interregular", sans-serif;
  font-size: 15px;
  color: #686868;
  letter-spacing: 1px;
}

.input:focus {
  border: 1px solid #b8b8b8;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px rgba(222, 222, 222, 0.6);
}

.input[readonly] {
  background-color: #f2f2f2;
}

.input[disabled] {
  background-color: #f2f2f2;
}

.input--textarea {
  height: 60px;
  padding-top: 10px;
  padding-bottom: 12px;
  /* Removes IE Scollbars */
  overflow: hidden;
  /* Removes Resize Arrow */
  resize: none;
}

.input--select {
  padding-right: 32px;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  appearance: none !important;
  cursor: pointer;
  background-color: transparent;
}
.input--select::-ms-expand {
  display: none;
}

.input--date {
  padding-left: 2px;
  padding-right: 20px;
  text-align: center;
  text-align-last: center;
}

.textarea {
  height: 134px;
  padding-top: 14px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 20px;
  resize: none;
}
.textarea::placeholder {
  color: #b1b1b1;
  font-family: "interregular", sans-serif;
  letter-spacing: 1.05px;
}

.select-wrapper {
  display: block;
  position: relative;
}
.select-wrapper:after, .select-wrapper:before {
  content: "";
  width: 7px;
  height: 1px;
  background-color: #7a7a7a;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  z-index: 0;
}
.select-wrapper:after {
  right: 19px;
  transform: rotate(45deg);
}
.select-wrapper:before {
  right: 14px;
  transform: rotate(-45deg);
}

.select-wrapper--v2:after, .select-wrapper--v2:before {
  width: 6px;
  height: 1px;
  background-color: #7a7a7a;
}
.select-wrapper--v2:after {
  right: 15px;
  transform: rotate(45deg);
}
.select-wrapper--v2:before {
  right: 11px;
  transform: rotate(-45deg);
}

.select-date-wrapper {
  display: flex;
  justify-content: space-between;
}

.select-wrapper--date {
  width: 32.5%;
}

/* ---------------- Checkbox/Radio ---------------- */
.checkbox-radio {
  display: flex;
  align-items: center;
  font-size: 15px;
}
.checkbox-radio__input {
  width: 1px;
  height: 1px;
  position: absolute;
  visibility: hidden;
}
.checkbox-radio__text {
  position: relative;
  line-height: 1.3;
  cursor: pointer;
}
.checkbox-radio__text--radio {
  padding-left: 30px;
}
.checkbox-radio__text--checkbox {
  padding-left: 30px;
}
.checkbox-radio__input + .checkbox-radio__text:before {
  box-sizing: border-box;
  display: block;
  content: "";
  position: absolute;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}
.checkbox-radio__input[type=radio] + .checkbox-radio__text:before {
  border: 1px solid black;
  background-color: #ffffff;
}
.checkbox-radio__text--radio:before {
  width: 20px;
  height: 20px;
  border-radius: 50%;
}
.checkbox-radio__text--checkbox:before {
  width: 20px;
  height: 20px;
  background-color: #4ACE3F;
  border-radius: 3px;
}
.checkbox-radio__text:after {
  box-sizing: border-box;
  display: none;
  content: "";
  position: absolute;
}
.checkbox-radio__text--radio:after {
  width: 8px;
  height: 8px;
  background-color: white;
  border-radius: 50%;
  left: 6px;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}
.checkbox-radio__text--checkbox:after {
  width: 14px;
  height: 8.4px;
  border-left: 3px solid white;
  border-bottom: 3px solid white;
  transform: rotate(-53deg) skew(-16deg);
  background-color: transparent;
  left: 3px;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  top: -3px;
}
.checkbox-radio__input:checked + .checkbox-radio__text:after {
  display: block;
}

.checkbox-radio--radio {
  margin-bottom: 15px;
}
.checkbox-radio--radio .checkbox-radio__text--checkbox:after {
  width: 14px;
  height: 14px;
  transform: unset;
  border: unset;
  border-radius: 50%;
  background: #4ACE3F;
  left: 3px;
  top: 1px;
}
.checkbox-radio--radio:not(:last-child) {
  margin-right: 24px;
}
.checkbox-radio--radio .checkbox-radio__text--checkbox:before {
  background: transparent;
  border-radius: 50%;
  border: 1px solid #4ACE3F;
}

.label-radio {
  display: block;
  position: relative;
  font-family: "interregular", sans-serif;
  font-size: 15px;
  color: #444;
  letter-spacing: 0.5px;
}
.label-radio__input {
  margin-right: 5px;
  position: absolute;
  top: 4px;
  left: 0;
}
.label-radio__text {
  display: block;
  padding-left: 20px;
  cursor: pointer;
}

/* ---------------- Substitution  / OLD Custom Select ---------------- */
.substitution-wrapper {
  position: relative;
}

.substitution {
  display: none;
  min-width: 100%;
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 1px;
  background-color: #fff;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.4);
  z-index: 1;
}
.substitution li {
  display: flex;
  align-items: center;
  min-height: 30px;
  padding-left: 15px;
  cursor: pointer;
  font-size: 15px;
}
.substitution li:hover {
  background-color: #dddddd;
}
.substitution.opened {
  display: block;
}

/* ---------------- Tooltip ---------------- */
.tooltip {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20px;
  position: absolute;
  top: 100%;
  padding-left: 8px;
  padding-right: 8px;
  font-family: "interregular", sans-serif;
  font-size: 13px;
  color: white;
  letter-spacing: 1px;
  text-transform: lowercase;
  white-space: nowrap;
  background-color: #9b9b9b;
  border-radius: 3px;
  z-index: 1;
}

.tooltip:after {
  position: absolute;
  top: -12px;
  left: 50%;
  content: "";
  display: block;
  margin-left: -6px;
  border: 6px solid transparent;
  border-bottom: 6px solid #9b9b9b;
}

.tooltip-error {
  background-color: #ff6a60;
}

.tooltip-error:after {
  border-bottom-color: #ff6a60;
}

.input-error {
  border: 1px solid #ff6a60 !important;
}

/* ---------------- Plus / Minus ---------------- */
.pm {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 22px;
  height: 22px;
  background-color: #151515;
  border-radius: 50px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: all 0.4s ease-in;
  font-size: 0;
}
.pm:hover:after, .pm:hover:before {
  transform: rotate(180deg);
}
.pm:after, .pm:before {
  display: none;
  content: "";
  background-color: white;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  border-radius: 5px;
  transition: transform 0.4s ease-out;
}
.pm:after {
  display: block;
  width: 12px;
  height: 2px;
}
.pm:before {
  display: block;
  width: 2px;
  height: 12px;
}
.pm.minus:after {
  visibility: visible;
}
.pm.minus:before {
  visibility: hidden;
}
.pm.plus:after {
  visibility: visible;
}
.pm.plus:before {
  visibility: visible;
}

.opened > .pm.plus:before {
  visibility: hidden !important;
}

/* ---------------- Arrow ---------------- */
.arrow {
  display: flex;
  padding-right: 14px;
  position: relative;
  z-index: 0;
}
.arrow:after {
  content: "";
  width: 8px;
  height: 8px;
  border: 1px solid #333333;
  border-top: none;
  border-left: none;
  transform: rotateZ(-45deg);
}

/* ---------------- Table ---------------- */
.table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
}
.table thead tr, .table tr:first-child {
  height: 46px;
}
.table thead td, .table th {
  color: black;
  line-height: 1.3;
}
.table td, .table th {
  border: 1px solid black;
  color: black;
}
.table tr {
  height: 36px;
}
.table tr:nth-child(odd) {
  background-color: #faf8f5;
}
.table tr:nth-child(even) {
  background-color: #fff;
}
.table td > * {
  vertical-align: middle;
}

/* ---------------- Other---------------- */
.tabs__tab {
  display: none !important;
}
.tabs__tab.active {
  display: flex !important;
}

.close-button {
  display: flex;
  align-items: center;
  justify-content: center;
}
.close-button:before, .close-button:after {
  content: "";
  height: 28px;
  width: 2px;
  position: absolute;
  background-color: #686868;
  border-radius: 4px;
}
.close-button:before {
  transform: rotateZ(45deg);
}
.close-button:after {
  transform: rotateZ(-45deg);
}

.menu-arrow {
  position: relative;
  z-index: 0;
}
.menu-arrow:after {
  content: "▼";
  height: 8px;
  line-height: 10px;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  right: 0;
  font-size: 0.7em;
  z-index: -1;
}

/* #m1100 */
@media (max-width: 1100px) {
  .container {
    padding-left: 15px;
    padding-right: 15px;
  }
  .padding {
    padding-left: 15px;
    padding-right: 15px;
  }
}
/* #m900 */
@media (max-width: 989px) {
  body {
    min-width: 320px;
  }
  .s-main__wrapper {
    padding-left: 0;
  }
  .s-main__left-column {
    display: none;
  }
  .container {
    padding-left: 12px;
    padding-right: 12px;
  }
  .padding {
    padding-left: 12px;
    padding-right: 12px;
  }
  .title {
    font-size: 22px;
  }
}
/* #m700 */
@media (max-width: 767px) {
  .container {
    padding-left: 12px;
    padding-right: 12px;
  }
  .padding {
    padding-left: 5px;
    padding-right: 5px;
  }
  .title {
    font-size: 20px;
  }
}
/* #m600 */
@media (max-width: 619px) {
  .title {
    font-size: 17px;
  }
}
/* #m400 */
@media (max-width: 439px) {
  .tooltip {
    padding-left: 4px;
    padding-right: 4px;
    font-size: 12px;
    letter-spacing: 0;
  }
}
/* --- Main ---*/
/* ---------------- Header ---------------- */
.wrapper {
  margin-top: 64px;
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 99;
  background: #fff;
  padding-top: 12px;
  padding-bottom: 12px;
  border-bottom: 1px solid #E3E3E3;
}
.header__wrapper {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.header__columns {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 54%;
  position: relative;
}
.header__column-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 44%;
}
.header__column-wrapper.phone {
  justify-content: flex-end;
}
.header__column-wrapper.phone .header__contact-mobile {
  margin-right: 30px;
}
.header__column--4 {
  display: flex;
}
.header__navigation-wrapper {
  margin-bottom: 0;
  display: initial;
  position: relative;
}
.header__navigation-wrapper:after {
  content: url("/svg/navigation.svg");
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(calc(-50% + 2px));
}
.header__navigation {
  padding-top: 11px;
  padding-bottom: 11px;
  padding-left: 40px;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 6px;
}
.header__navigation::placeholder {
  color: #B8B8B8;
}
.header__navigation-window {
  display: none;
  flex-direction: column;
  width: 335px;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 4px;
  padding-bottom: 4px;
  position: absolute;
  top: 78px;
  left: 33px;
  z-index: 10;
  background: #FFFFFF;
  border-radius: 6px;
  box-shadow: 0px 2px 12px -2px rgba(21, 21, 21, 0.15);
}
.header__navigation-window_mobile {
  position: fixed;
  top: 71px;
  left: 0;
  z-index: 100;
  width: 100%;
  height: 100vh;
  display: none;
  background: rgba(21, 21, 21, 0.5);
  backdrop-filter: blur(8px);
}
.header__navigation-window_mobile.active {
  display: flex;
  align-items: baseline;
}
.header__navigation-window_mobile .header__navigation-window_mobile-list {
  position: relative;
  top: 0;
  left: 0;
  margin: 20px;
  background: white;
  border-radius: 6px;
  width: 100%;
}
.header__navigation-window_mobile .header__navigation-window_mobile-list .header__navigation-wrapper {
  display: block;
  width: 100%;
}
.header__navigation-window_mobile .header__navigation-window_mobile-list .header__navigation-wrapper:after {
  left: auto;
  top: 12px;
  right: 12px;
}
.header__navigation-window_mobile .header__navigation-window_mobile-list .header__navigation-wrapper input {
  padding-left: 12px;
  width: 100%;
}
.header__navigation-window_mobile .header__navigation-window_mobile-list .header__navigation-item {
  padding: 0 12px;
}
.header__navigation-window.active:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.header__navigation-item {
  display: flex;
  align-items: center;
  height: 42px;
  color: #B8B8B8;
}
.header__navigation-item span {
  color: #151515;
  margin-right: 5px;
}
.header__navigation-item:not(:last-child) {
  border-bottom: 1px solid #E3E3E3;
}
.header__navigation-window.active {
  display: flex;
}
.header__contact {
  position: relative;
  font-size: 15px;
  font-weight: bold;
  color: #151515;
  margin-right: 15px;
}
.header__contact:after {
  content: url("/svg/phone.svg");
  position: absolute;
  left: -36px;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  top: -4px;
}
.header__halal_img {
  position: absolute;
  bottom: -85px;
  right: 15px;
  z-index: 101;
  width: 60px;
}
.header__halal_img img {
  width: 100%;
}
@media (max-width: 989px) {
  .header__halal_img {
    right: 27px;
    width: 35px;
    bottom: -60px;
  }
}
.header__logo {
  display: flex;
  align-items: center;
}
.header__logo-mobile {
  display: none;
}
.header__navigation-mobile {
  display: none;
}
.header__contact-mobile {
  display: none;
}

/* #m1300 */
/* #m1200 */
@media (max-width: 1220px) {
  .header__columns {
    width: 67%;
  }
}
/* #m1100 */
/* #m900 */
@media (max-width: 989px) {
  .wrapper {
    margin-top: 57px;
  }
  .header__navigation-window {
    display: none !important;
  }
  .header__logo {
    display: none;
  }
  .header__columns {
    width: 100%;
  }
  .header__column-wrapper {
    width: 20%;
  }
  .header__navigation-wrapper {
    display: none;
  }
  .header__logo-mobile {
    display: flex;
  }
  .header__navigation-mobile {
    display: flex;
  }
  .header__contact-mobile {
    display: flex;
  }
  .header__contact {
    display: none;
  }
}
/* #m800 */
/* #m700 */
@media (max-width: 767px) {
  .header__column--1 {
    display: flex;
    align-items: flex-end;
    height: 14px;
  }
}
/* #m600 */
/* #m500 */
@media (max-width: 559px) {
  .header__column-wrapper {
    width: 25%;
  }
}
/* #m400 */
/* #m400 */
/* #m300 */
/* ---------------- Footer ---------------- */
.footer {
  display: flex;
  flex-direction: column;
}
.footer__columns {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 20px;
}
.footer__column {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 20px;
}
.footer__column-title {
  margin-bottom: 11px;
  font-size: 13px;
  color: #B8B8B8;
}
.footer__column-item {
  font-size: 15px;
  color: #151515;
}
.footer__column-item:not(:last-child) {
  margin-bottom: 10px;
}

/* #m1300 */
/* #m1200 */
/* #m1100 */
/* #m900 */
@media (max-width: 989px) {
  .footer__column {
    margin-bottom: 24px;
  }
  .footer__column:last-child {
    flex: 1 1 100%;
  }
  .footer__column:nth-child(1) {
    order: 2;
  }
  .footer__column:nth-child(2) {
    order: 1;
  }
  .footer__column:nth-child(3) {
    order: 6;
  }
  .footer__column:nth-child(4) {
    order: 3;
  }
  .footer__column:nth-child(5) {
    order: 7;
  }
  .footer__column:nth-child(6) {
    order: 5;
  }
  .footer__column:nth-child(7) {
    order: 8;
  }
}
/* #m800 */
/* #m700 */
/* #m600 */
/* #m500 */
/* #m400 */
@media (max-width: 479px) {
  .footer__column:nth-child(3) {
    width: 100%;
  }
}
/* #m400 */
/* #m300 */
/* ---------------- Breadcrumbs ---------------- */
.breadcrumbs {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 21px;
  padding-top: 30px;
  letter-spacing: 1.05px;
}
.breadcrumbs__item {
  display: flex;
  align-items: center;
  margin-left: 8px;
}
.breadcrumbs__item:after {
  content: "/";
  margin-left: 8px;
}
.breadcrumbs__item:last-child {
  opacity: 0.5;
}
.breadcrumbs__item:last-child:after {
  display: none;
}
.breadcrumbs__link {
  font-family: "interregular", sans-serif;
  font-size: 15px;
  color: #151515;
}

@media (max-width: 990px) {
  .breadcrumbs {
    height: 79px;
  }
  .breadcrumbs__link {
    font-size: 15px;
  }
}
@media (max-width: 620px) {
  .breadcrumbs {
    height: 57px;
    margin-top: 0;
    margin-bottom: 0;
    padding: 0;
  }
  .breadcrumbs__link {
    font-size: 14px;
  }
}
/* ---------------- Mobile button ---------------- */
.mobile-button {
  display: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.mobile-button__wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 22px;
  margin-right: 12px;
  position: relative;
}
.mobile-button__text {
  font-family: "interregular", sans-serif;
  font-size: 22px;
  color: white;
  letter-spacing: 1px;
  text-transform: uppercase;
}
.mobile-button__button, .mobile-button__button:before, .mobile-button__button:after {
  display: block;
  content: "";
  height: 2px;
  width: 100%;
  position: absolute;
  background: white;
  border-radius: 1px;
  cursor: pointer;
  transition: all 300ms ease-in-out;
}
.mobile-button__button {
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}
.mobile-button__button:after {
  top: -9.3333333333px;
}
.mobile-button__button:before {
  bottom: -9.3333333333px;
}
.mobile-button.active .mobile-button__button {
  background-color: transparent;
}
.mobile-button.active .mobile-button__button:before, .mobile-button.active .mobile-button__button:after {
  top: 0;
}
.mobile-button.active .mobile-button__button:before {
  transform: rotate(45deg);
}
.mobile-button.active .mobile-button__button:after {
  transform: rotate(-45deg);
}

@media (max-width: 989px) {
  .mobile-button {
    display: flex;
  }
}
/* ---------------- Paginator ---------------- */
.paginator {
  display: flex;
  justify-content: center;
  padding-top: 12px;
  margin-bottom: 20px;
  margin-left: 25px;
}
.paginator__block {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  align-items: center;
}
.paginator__block--1 {
  justify-content: flex-start;
}
.paginator__block--2 {
  flex-grow: 2;
  flex-shrink: 0;
  justify-content: center;
}
.paginator__block--3 {
  justify-content: flex-end;
}
.paginator__label {
  font-size: 15px;
  color: #1b1b1b;
}
.paginator__select-wrapper {
  width: 206px;
  height: 34px;
  margin-left: 8px;
  line-height: normal;
}
.paginator__select-wrapper--limit {
  width: 80px;
}
.paginator__select-wrapper--limit > .paginator__select {
  padding-left: 18px;
}
.paginator__select {
  width: 100%;
  height: 100%;
  padding-left: 16px;
  padding-right: 25px;
  border: 1px solid #151515;
  border-radius: 15px;
  font-family: "interregular", sans-serif;
  font-size: 15px;
  color: #686868;
}
.paginator__select > option {
  font-family: "interregular", sans-serif;
  font-size: 15px;
}
.paginator__list {
  display: flex;
  justify-content: center;
}
.paginator__item {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  margin-right: 5px;
  border: 1px solid #151515;
}
.paginator__item:last-child {
  margin-right: 0;
}
.paginator__item.active, .paginator__item:hover {
  border-color: black;
  background-color: black;
}
.paginator__item.active > .paginator__link, .paginator__item:hover > .paginator__link {
  color: white;
}
.paginator__item:hover .paginator__arrow-svg {
  fill: white;
}
.paginator__item--dotes:hover {
  border-color: #151515;
  background-color: inherit;
}
.paginator__dots, .paginator__dots:after, .paginator__dots:before {
  margin-top: 8px;
  width: 2px;
  height: 2px;
  position: relative;
  background-color: #7e7e7e;
  border-radius: 50%;
  transition: all 0.2s linear;
}
.paginator__dots:after, .paginator__dots:before {
  position: absolute;
  display: flex;
  content: "";
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}
.paginator__dots:after {
  left: 5px;
}
.paginator__dots:before {
  right: 5px;
}
.paginator__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  font-family: "interregular", sans-serif;
  font-size: 15px;
  color: #686868;
}
.paginator__link:hover {
  text-decoration: none;
}
.paginator__arrow-svg {
  width: 15px;
  height: 15px;
  fill-rule: evenodd;
  fill: black;
}

.paginator--v2 {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}
.paginator--v2__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px;
  height: 42px;
  border-radius: 5px;
  font-family: "interregular", sans-serif;
  font-size: 18px;
}
.paginator--v2__item:hover {
  background-color: #dae4fb;
}
.paginator--v2__item--dotes {
  width: 30px;
  letter-spacing: 1px;
}
.paginator--v2__item--dotes:hover {
  background-color: inherit;
}
.paginator--v2__link {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  color: #29292b;
}
.paginator--v2 .active {
  background-color: #004cff;
  border-radius: 10px;
  color: #fff;
}
.paginator--v2 .active > * {
  color: #fff;
}

@media (max-width: 989px) {
  .paginator {
    margin-left: 0;
  }
  .paginator__label {
    display: none;
  }
}
@media (max-width: 619px) {
  .paginator__select-wrapper--sort {
    width: 175px;
  }
  .paginator__item--2 {
    text-align: right;
  }
}
@media (max-width: 559px) {
  .paginator__item:not([data-navigaton]) {
    display: none;
  }
  .paginator__item {
    width: 40px;
    height: 40px;
    margin-right: 5px;
  }
  .paginator__item.active {
    display: block;
  }
}
@media (max-width: 439px) {
  .paginator__select-wrapper {
    height: 31px;
  }
  .paginator__select {
    font-size: 14px;
  }
}
/* ---------------- Dropmenu ---------------- */
.dropmenu {
  padding-right: 23px;
  position: relative;
  font-size: 15px;
  font-weight: bold;
  cursor: pointer;
  transition: all 0.2s ease-out;
}
.dropmenu:after {
  content: url("/svg/arrow-down.svg");
  position: absolute;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
}
.dropmenu:before .dropmenu.active {
  content: "";
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 6px solid white;
  position: absolute;
  top: 59px;
  left: 20px;
}
.dropmenu__list {
  display: none;
  grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
  column-gap: 10px;
  align-items: center;
  width: 595px;
  padding: 20px 40px;
  position: absolute;
  left: -277px;
  top: 65px;
  background: white;
  border-radius: 16px;
  z-index: 100;
  transition: all 0.2s ease-out;
  box-shadow: 0 2px 12px -2px rgba(21, 21, 21, 0.15);
}
.dropmenu.active .dropmenu__list {
  display: grid;
}
.dropmenu.active .dropmenu__list:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}
.dropmenu__item {
  display: flex;
  align-items: center;
  width: 33.3%;
  height: 72px;
}
.dropmenu__item .safari_placeholder {
  display: none;
}
.dropmenu__item:hover .dropmenu__item-text {
  color: #4ACE3F;
}
.dropmenu__item:hover {
  text-decoration: none;
}
.dropmenu__item-image {
  margin-right: 13px;
  width: 32px;
  height: 32px;
}
.dropmenu__item-text {
  font-size: 18px;
  font-weight: bold;
  color: #151515;
}
.dropmenu.active .dropmenu__list:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-left: 8px solid transparent;
  border-right: 8px solid transparent;
  border-bottom: 6px solid white;
  top: -6px;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
}

/* #m1300 */
/* #m1200 */
/* #m1100 */
/* #m900 */
@media (max-width: 989px) {
  .dropmenu__list {
    left: 0;
  }
  .dropmenu.active .dropmenu__list:before {
    display: none;
  }
}
/* #m800 */
/* #m700 */
@media (max-width: 767px) {
  .dropmenu {
    width: 12px;
    height: 2px;
    padding-right: 0;
    background: #151515;
    font-size: 0;
  }
  .dropmenu:after, .dropmenu:before {
    content: "";
    border: unset;
    background: #151515;
    width: 24px;
    height: 2px;
    position: absolute;
    top: -13px;
    left: 0;
  }
  .dropmenu:before {
    top: -12px;
  }
  .dropmenu.active {
    background: transparent;
  }
  .dropmenu.active:after, .dropmenu.active:before {
    margin-top: auto;
    margin-bottom: auto;
    top: 0;
    bottom: 0;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    transform: rotate(45deg);
  }
  .dropmenu.active:before {
    transform: rotate(135deg);
  }
  .dropmenu.active .dropmenu__list {
    display: block !important;
  }
  .dropmenu__list {
    overflow: auto;
    height: calc(100vh - 57px);
    flex-direction: column;
    width: 100vw;
    margin-left: -12px;
    padding-left: 0;
    border-radius: 0;
    top: 24px;
  }
  .dropmenu__item {
    width: 100%;
    padding-left: 10px;
    border-bottom: 1px solid #E3E3E3;
  }
  .dropmenu__item.safari_placeholder {
    display: flex;
    border: none;
  }
}
/* #m600 */
/* #m500 */
/* #m400 */
/* #m400 */
/* #m300 */
/* ---------------- Slider ---------------- */
.slider {
  width: 100%;
  height: 600px;
  position: relative;
  background-color: #F8F8F8;
}
.slider__sale {
  position: absolute;
  right: 40px;
  top: -20px;
  z-index: 10;
}
.slider > ul {
  width: 100%;
}
.slider__item {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out 0s;
  text-align: center;
}
.slider__item.active {
  opacity: 1;
  z-index: 1;
}
.slider__link {
  width: 100%;
  height: 100%;
  position: absolute;
}
.slider__info-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
  width: 700px;
  height: 46%;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  left: -35%;
  font-family: "interregular", sans-serif;
  color: #333333;
  letter-spacing: 1px;
  line-height: 1.4;
  text-align: center;
  z-index: 1;
}
.slider__title {
  margin-bottom: 32px;
  font-family: "interbold", sans-serif;
  font-size: 64px;
  color: white;
  text-transform: uppercase;
  text-align: left;
}
.slider__text {
  max-width: 200px;
  margin-bottom: 32px;
  font-size: 24px;
  color: white;
  text-align: left;
}
.slider__button {
  width: 40px;
  height: 40px;
  background: #4ACE3F;
  border-radius: 50%;
  position: relative;
}
.slider__button:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  border-left: 1px solid #fff;
  border-bottom: 1px solid #fff;
  transform: rotate(45deg);
}
.slider__button--right:after {
  transform: rotate(-135deg);
}
.slider__arrows-block {
  display: flex;
  align-items: flex-end;
  height: 70px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  z-index: 2;
}
.slider__arrow {
  display: flex;
  align-items: center;
  width: 75px;
  height: 68px;
  background-color: #ffffff;
  cursor: pointer;
  opacity: 1;
  transition: filter 0.2s ease;
}
.slider__arrow--left {
  justify-content: flex-end;
  padding-right: 10px;
}
.slider__arrow--right {
  padding-left: 10px;
}
.slider__arrow-svg {
  fill: black;
  fill-rule: evenodd;
  width: 33px;
  height: 33px;
}
.slider__arrow:hover {
  filter: brightness(90%);
}
.slider__navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 200px;
}
.slider__controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 60px;
  font-size: 0;
  text-align: center;
  z-index: 1;
}
.slider__toggle {
  width: 12px;
  height: 12px;
  position: relative;
  border-radius: 50%;
  background-color: #a7a7a9;
  transition: all 0.3s ease-out;
}
.slider__toggle:last-child {
  margin-right: 0;
}
.slider__toggle:only-child {
  display: none;
}
.slider__toggle.active {
  background-color: #4ACE3F;
}

@media (max-width: 1220px) {
  .slider {
    height: 300px;
  }
  .slider__item {
    max-width: unset;
    align-items: flex-start;
    height: 300px;
  }
  .slider__title {
    width: fit-content;
    margin-bottom: 20px;
    font-size: 32px;
  }
  .slider__text {
    margin-bottom: 20px;
    font-size: 18px;
    text-align: left;
  }
  .slider__info-block {
    width: auto;
    left: 0;
  }
}
/* #m1300 */
/* #m1200 */
/* #m1100 */
/* #m900 */
/* #m800 */
/* #m700 */
@media (max-width: 768px) {
  .slider {
    height: 220px;
    margin-bottom: 30px;
    position: relative;
  }
  .slider__button {
    display: none;
  }
  .slider__navigation {
    justify-content: center;
    position: absolute;
    bottom: -20px;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
  }
  .slider__item {
    height: 220px;
    background-size: cover;
  }
  .slider__title {
    font-size: 32px;
  }
  .slider__text {
    font-size: 16px;
  }
  .slider__info-block {
    max-width: unset;
    justify-content: flex-start;
    padding-left: 20px;
    padding-top: 24px;
    height: auto;
    left: 0;
  }
  .slider__sale {
    display: none;
  }
}
/* #m600 */
/* #m500 */
/* #m400 */
/* #m400 */
/* #m300 */
/* ---------------- Categories ---------------- */
.categories {
  display: flex;
  flex-wrap: wrap;
  padding-top: 60px;
  padding-bottom: 60px;
}
.categories__item {
  display: flex;
  width: 23%;
  height: 246px;
  margin-bottom: 25px;
  margin-right: 25px;
  position: relative;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 12px;
  overflow: hidden;
  color: #151515;
}
.categories__item:hover {
  color: #4ACE3F;
  text-decoration: none;
}
.categories__item-title {
  margin-left: 24px;
  margin-top: 24px;
  font-family: "interbold", sans-serif;
  font-size: 20px;
  z-index: 1;
}
.categories__item-image {
  transition: 0.1s all linear;
  position: absolute;
  width: 75%;
  height: 75%;
  bottom: 0;
  right: 0;
  border-radius: 12px;
  object-fit: contain;
}
.categories__item:hover .categories__item-image {
  transform: scale(1.2);
}

/* #m1300 */
@media (max-width: 1366px) {
  .categories__item {
    width: 30%;
  }
}
/* #m1200 */
/* #m1100 */
/* #m900 */
/* #m800 */
/* #m700 */
@media (max-width: 767px) {
  .categories {
    justify-content: space-between;
    margin-right: 0;
    padding-top: 25px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 20px;
  }
  .categories__item {
    width: 47%;
    height: 130px;
    margin-right: 0;
  }
  .categories__item-image {
    width: 80%;
  }
  .categories__item-title {
    margin-top: 16px;
    margin-left: 15px;
    font-size: 15px;
  }
}
/* #m600 */
/* #m500 */
/* #m400 */
/* #m400 */
/* #m300 */
/* ---------------- Social-list ---------------- */
.social-list {
  display: flex;
  justify-content: space-between;
  width: 72px;
}

/* #m1300 */
/* #m1200 */
/* #m1100 */
/* #m900 */
/* #m800 */
/* #m700 */
/* #m600 */
/* #m500 */
/* #m400 */
/* #m400 */
/* #m300 */
/* ---------------- Cart ---------------- */
.cart {
  display: flex;
  align-items: center;
  position: relative;
  /* После изменения HTML поправить тут на старые стили */
}
.cart__button {
  margin-right: 18px;
  position: relative;
  cursor: pointer;
}
.cart__value {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 16px;
  height: 16px;
  position: absolute;
  right: -8px;
  top: -6px;
  background: #4ACE3F;
  border-radius: 50%;
  font-size: 12px;
  color: #fff;
}
.cart__text {
  font-size: 15px;
  font-weight: bold;
}
.cart__window {
  display: flex;
  flex-direction: column;
  width: 420px;
  max-height: calc(100vh - 143px);
  overflow: auto;
  padding-top: 12px;
  padding-bottom: 16px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #E3E3E3;
  background: #fff;
  box-sizing: border-box;
  border-radius: 12px;
  box-shadow: 0 2px 12px -2px rgba(21, 21, 21, 0.15);
  z-index: 100;
  transition: all 0.2s ease-out;
}
.header__column--4 + .cart {
  position: absolute;
  top: 200%;
  right: 0;
  visibility: hidden;
  opacity: 0;
}
.header__column--4:hover + .cart {
  opacity: 1;
  visibility: visible;
}
.cart__item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  border-bottom: 1px solid #E3E3E3;
}
.cart__item:not(:last-child) {
  margin-bottom: 12px;
}
.cart__item-image {
  width: 90px;
  height: 72px;
  overflow: hidden;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 12px;
}
.cart__item-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cart__item-body {
  display: flex;
  flex-direction: column;
  width: 206px;
}
.cart__item-title {
  font-size: 15px;
  font-weight: bold;
}
.cart__item-title a {
  color: #000;
}
.cart__item-wrapper {
  display: flex;
  align-items: center;
}
.cart__item-price {
  font-size: 15px;
  margin-right: 8px;
}
.cart__item-count {
  font-size: 15px;
  color: #B8B8B8;
}
.cart__button-delete {
  position: relative;
  width: 16px;
  height: 16px;
  overflow: hidden;
}
.cart__button-delete:after, .cart__button-delete:before {
  content: "";
  width: 16px;
  height: 1.5px;
  position: absolute;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  transform: rotate(45deg);
  background: #B8B8B8;
}
.cart__button-delete:before {
  transform: rotate(-45deg);
}
.cart__bottom {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.cart__total-price {
  display: flex;
  justify-content: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 18px;
  font-weight: bold;
}
.cart__button-buy {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  font-size: 15px;
  color: #fff;
  background: #4ACE3F;
  box-shadow: 0px 2px 8px rgba(74, 206, 63, 0.3);
  border-radius: 6px;
}
.cart__button-buy:hover {
  background: #3BA532;
}
.cart__empty {
  display: none;
  justify-content: center;
  align-items: center;
  height: 84px;
}
.cart__empty.active {
  display: flex;
}
.cart__empty-text {
  display: flex;
  align-items: center;
  height: 108px;
  margin-left: auto;
  margin-right: auto;
  max-width: 155px;
  font-size: 15px;
  color: #B8B8B8;
  text-align: center;
}
.cart__item-price--mobile {
  display: none;
}

.cart--order {
  flex-direction: column;
  padding-top: 20px;
  margin-bottom: 30px;
}
.cart--order .cart__item {
  width: 100%;
  padding-bottom: 20px;
}
.cart--order .cart__item-price {
  font-family: "interbold", sans-serif;
}
.cart--order .cart__item-image {
  margin-right: 20px;
}
.cart--order .cart__price--mobile {
  display: none;
  font-family: "interregular", sans-serif;
  font-size: 15px;
}

.cart--window {
  display: none;
  justify-content: flex-end;
  align-items: flex-start;
  max-width: 1290px;
  width: 100%;
  height: 100%;
  position: absolute;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  top: 13px;
  opacity: 0;
  overflow: hidden;
}
.cart--window.active {
  display: flex;
  opacity: 1;
  overflow: visible;
  position: fixed;
  top: 90px;
  z-index: 100;
}

/* #m1300 */
@media (max-width: 1366px) {
  .cart--window {
    right: 20px;
    left: unset;
  }
}
/* #m1200 */
/* #m1100 */
@media (max-width: 1100px) {
  .cart--window {
    right: 15px;
  }
}
/* #m900 */
@media (max-width: 989px) {
  .cart__text {
    display: none;
  }
  .cart--window {
    right: 12px;
  }
}
/* #m800 */
/* #m700 */
@media (max-width: 767px) {
  .cart__window {
    border-radius: unset;
    right: 0;
    top: 43px;
  }
  .cart--window .cart__window {
    border-radius: 12px;
  }
}
/* #m600 */
@media (max-width: 619px) {
  .cart--window {
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    right: 0;
    left: 0;
    top: 0;
    z-index: 10;
    background: rgba(0, 0, 0, 0.6);
  }
  .cart--window .cart__window {
    width: 100%;
    border-radius: unset;
  }
  .cart__item-body {
    flex-grow: 1;
    margin-left: 10px;
    margin-right: 10px;
    border: unset;
  }
}
/* #m500 */
@media (max-width: 559px) {
  .cart--order .cart__button-delete {
    margin-left: 15px;
  }
  .cart--order .cart__item-price {
    display: none;
  }
  .cart--order .cart__item-price--mobile {
    display: flex;
  }
  .cart--order .cart__item-body {
    width: 70%;
  }
  .cart--order .cart__item-wrapper {
    width: 87%;
  }
  .cart__button-delete {
    margin-left: 15px;
  }
}
/* #m400 */
@media (max-width: 479px) {
  .header__column--4 + .cart {
    align-items: flex-start;
    width: 106%;
    height: 100vw;
    top: 44px;
    right: -12px;
    z-index: 10;
    background: rgba(0, 0, 0, 0.5);
  }
  .cart__window {
    width: 100%;
    right: 0;
    border: unset;
    border-top: 1px solid #E3E3E3;
  }
}
/* #m400 */
/* #m300 */
/* ---------------- Quantity-selector ---------------- */
.quantity-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32px;
  height: 72px;
  padding-top: 10px;
  padding-bottom: 10px;
  border: 1px solid #E3E3E3;
  border-radius: 6px;
}
.quantity-selector__button-wrapper {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  min-width: 35%;
  height: 100%;
}
.quantity-selector__button-wrapper > .quantity-selector__button:first-child {
  box-sizing: content-box;
}
.quantity-selector__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  position: relative;
  font-size: 14px;
  line-height: 1;
}
.quantity-selector__input {
  flex-grow: 1;
  height: 100%;
  font-size: 13px;
  text-align: center;
  transition: background-color 0.25s ease-out 0s;
}
.quantity-selector .pm {
  width: 8px;
  height: 8px;
}
.quantity-selector .minus, .quantity-selector .plus {
  background-color: unset;
}
.quantity-selector .minus:after, .quantity-selector .plus:after, .quantity-selector .plus:before {
  background-color: #4ACE3F;
  transition: none;
}
.quantity-selector .minus:after {
  width: 8px;
  height: 2px;
}
.quantity-selector .plus:after {
  width: 8px;
  height: 2px;
}
.quantity-selector .plus:before {
  width: 2px;
  height: 8px;
}
.quantity-selector--card {
  flex-direction: row;
  height: 48px;
  width: 72px;
  border: unset;
  overflow: hidden;
}
.quantity-selector--card .quantity-selector__button-wrapper {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-between;
  height: 48px;
}
.quantity-selector--card .minus:after, .quantity-selector--card .plus:after, .quantity-selector--card .plus:before {
  background: white;
}
.quantity-selector--card .quantity-selector__input {
  color: #fff;
  flex-grow: unset;
  height: 48px;
  max-width: 47px;
  width: 100%;
  border-left: 1px solid #80dd78;
  border-right: 1px solid #80dd78;
}
.quantity-selector--card .pm {
  height: 23px;
}
.quantity-selector--card .plus {
  width: 100%;
  border-bottom: 1px solid #80dd78;
  border-radius: unset;
}

.quantity-selector--row {
  width: 122px;
  height: 44px;
}
.quantity-selector--row .quantity-selector__button-wrapper {
  flex-direction: row;
  width: 100%;
}

.quantity-selector--product {
  display: none;
  width: 48%;
}
@media (max-width: 1236px) {
  .quantity-selector--card {
    width: 57px;
  }
  .quantity-selector--card .quantity-selector__input {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 860px) {
  .quantity-selector--card {
    display: none;
  }
  .quantity-selector--product {
    display: flex;
    height: 44px;
  }
  .quantity-selector--product .quantity-selector__button-wrapper {
    flex-direction: row;
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
  .quantity-selector--product .quantity-selector__button {
    height: 100%;
    width: 25%;
  }
}
@media (max-width: 620px) {
  .quantity-selector--row {
    width: 32px;
    height: 64px;
  }
  .quantity-selector--row .quantity-selector__button-wrapper {
    flex-direction: column-reverse;
  }
}
/* ---------------- Filter ---------------- */
.filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.filter__column {
  display: flex;
  align-items: center;
}
.filter__column--1 {
  align-items: baseline;
}
.filter__title {
  margin-right: 20px;
  font-size: 32px;
  font-weight: bold;
}
.filter__text {
  font-size: 15px;
  color: #B8B8B8;
}

/* #m1300 */
/* #m1200 */
/* #m1100 */
/* #m900 */
/* #m800 */
/* #m700 */
@media (max-width: 767px) {
  .filter {
    flex-direction: column;
    align-items: flex-start;
  }
  .filter__column--1 {
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
  }
  .filter__column--2 {
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }
  .filter__column--2 .filter__text {
    margin-bottom: 10px;
  }
}
/* #m600 */
/* #m500 */
/* #m400 */
/* #m400 */
/* #m300 */
/* ---------------- Select ---------------- */
.select {
  margin-left: 20px;
  color: #151515;
  position: relative;
}
.select:after {
  content: "";
  width: 15px;
  height: 15px;
  border-bottom: 1px solid #B8B8B8;
  border-left: 1px solid #B8B8B8;
  position: absolute;
  top: 20%;
  right: 21px;
  transform: rotate(-45deg);
}
.select__input {
  width: 335px;
  height: 48px;
  padding: 15px;
  appearance: none;
  -webkit-appearance: none;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 6px;
}
.select option {
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
}

/* #m1300 */
/* #m1200 */
/* #m1100 */
/* #m900 */
/* #m800 */
/* #m700 */
@media (max-width: 767px) {
  .select {
    width: 100%;
    margin-left: 0;
  }
  .select__input {
    width: 100%;
  }
}
/* #m600 */
/* #m500 */
/* #m400 */
/* #m400 */
/* #m300 */
/* ---------------- Card ---------------- */
.card-wrapper {
  display: flex;
  flex-wrap: wrap;
  margin-top: 34px;
  margin-left: -25px;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 23.1%;
  height: 422px;
  margin-left: 1.9%;
  margin-bottom: 25px;
  padding-bottom: 20px;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  border-radius: 12px;
  z-index: 1;
}
.card__image {
  width: 100%;
  height: 200px;
  overflow: hidden;
  z-index: 0;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
}
.card__image-item {
  height: 100%;
}
.card__image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: 0.2s all ease-in-out;
}
.card__image img:hover {
  transform: scale(1.2);
}
.card__body {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
}
.card__link {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  color: #151515;
}
.card__description {
  display: flex;
  flex-direction: column;
}
.card__title {
  margin-bottom: 12px;
  font-size: 18px;
  font-weight: bold;
  color: #151515;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card__text {
  font-size: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card__product-info {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding-left: 24px;
  padding-right: 24px;
  padding-bottom: 12px;
}
.card__price {
  font-size: 20px;
  font-weight: bold;
}
.card__count {
  font-size: 15px;
  color: #B8B8B8;
}
.card__button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  margin-right: 24px;
  margin-left: 24px;
  position: relative;
  background: #4ACE3F;
  box-shadow: 0px 2px 8px rgba(74, 206, 63, 0.4);
  border-radius: 6px;
  cursor: pointer;
  overflow: hidden;
}
.card__button .not_in_cart {
  display: inline;
  color: #fff;
}
.card__button .in_cart {
  display: none;
  color: #fff;
}
.card__button.product_in_cart .not_in_cart {
  display: none;
}
.card__button.product_in_cart .in_cart {
  display: inline;
}
.card__button.product_in_cart .card__button-text {
  padding: 0;
}
.card__button.product_in_cart .quantity-selector--card {
  display: none;
}
.card__button:hover {
  background: #3BA532;
}
.card__button-text {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  height: 100%;
  padding-left: 20px;
  font-size: 15px;
  color: #fff;
  position: relative;
}
.card__button-text button {
  display: inline-flex;
  align-items: center;
}
.card__button-text img {
  width: 18px !important;
  margin-right: 10px;
}

.card--big {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 458px;
  margin-bottom: 200px;
  margin-top: 160px;
  margin-left: 0 !important;
  border: unset;
  position: relative;
}
.card--big .card__image {
  height: 100%;
  width: 50%;
}
.card--big .card__image img {
  width: 100%;
  height: 100%;
}
.card--big .card__body {
  width: 48%;
  height: 100%;
  padding-bottom: 0;
}
.card--big .card__title {
  font-family: "interbold", sans-serif;
  font-size: 32px;
}
.card--big .card__text {
  font-size: 18px;
}
.card--big .card__product-info {
  align-items: center;
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.card--big .card__price {
  font-size: 32px;
}
.card--big .card__count {
  font-size: 18px;
}
.card--big .card__button {
  margin-right: 0;
  margin-left: 0;
}
.card--big .card__button-back {
  position: absolute;
  top: -40px;
  left: 24px;
  font-size: 15px;
  color: #151515;
}
.card--big .card__button-back:before {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  left: -18px;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  border-bottom: 1px solid #151515;
  border-left: 1px solid #151515;
  transform: rotate(45deg);
}

/* #m1300 */
/* #m1200 */
/* #m1100 */
@media (max-width: 1100px) {
  .card {
    width: 30%;
    margin-left: 3.2%;
  }
  .card--big {
    width: 100%;
  }
}
/* #m900 */
/* #m800 */
@media (max-width: 859px) {
  .card--big {
    flex-direction: column;
    height: auto;
    margin-top: 46px;
    margin-bottom: 55px;
  }
  .card--big .card__body, .card--big .card__image {
    width: 100% !important;
    margin-left: auto;
    margin-right: auto;
  }
  .card--big .card__image {
    height: auto !important;
  }
  .card--big .card__image img {
    height: auto !important;
  }
  .card--big .card__product-info {
    margin-bottom: 20px;
  }
  .card--big .card__title {
    margin-bottom: 16px;
    font-size: 18px;
  }
  .card--big .card__text {
    margin-bottom: 16px;
    font-size: 15px;
    height: 60px;
  }
  .card--big .card__price {
    font-size: 18px;
  }
  .card--big .card__count {
    font-size: 15px;
  }
  .card--big .card__button-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .card--big .card__button {
    width: 48%;
    padding-left: 20px;
  }
  .card__button-text {
    padding-left: 0;
  }
  .card__title {
    font-size: 16px;
  }
}
/* #m700 */
@media (max-width: 767px) {
  .card-wrapper {
    justify-content: space-between;
    margin-left: 0;
    margin-bottom: 20px;
  }
  .card {
    width: 49%;
    height: auto;
    margin-left: 0;
    margin-bottom: 5px;
    padding-bottom: 10px;
  }
  .card__image, .card img {
    width: 100%;
    height: 100%;
  }
  .card__body {
    padding-left: 12px;
    padding-right: 12px;
    padding-bottom: 12px;
  }
  .card__title {
    font-size: 15px;
  }
  .card__text {
    font-size: 13px;
    height: 50px;
  }
  .card__price {
    font-size: 15px;
  }
  .card__count {
    font-size: 13px;
  }
  .card__button {
    margin-left: 12px;
    margin-right: 12px;
  }
  .card__product-info {
    padding-left: 12px;
    padding-right: 12px;
  }
  .card--big {
    width: 100%;
    margin-bottom: 57px;
  }
}
/* #m600 */
/* #m500 */
/* #m400 */
/* #m400 */
/* #m300 */
@media (max-width: 375px) {
  .card--big .card__text {
    height: 50px;
  }
  .card .card__body .card__title {
    font-size: 12px;
    margin-bottom: 6px;
  }
  .card .card__body .card__text {
    font-size: 11px;
  }
  .card .card__product-info {
    white-space: nowrap;
  }
  .card .card__product-info .card__price {
    font-size: 12px;
  }
  .card .card__product-info .card__count {
    font-size: 10px;
  }
}
/* ---------------- Misc ---------------- */
.link-back {
  display: flex;
  align-items: center;
  padding-left: 16px;
  font-size: 15px;
  position: relative;
  color: #B8B8B8;
}
.link-back:after {
  content: "";
  width: 10px;
  height: 10px;
  position: absolute;
  left: 0;
  margin-top: auto;
  margin-bottom: auto;
  top: 0;
  bottom: 0;
  border-bottom: 1px solid #B8B8B8;
  border-left: 1px solid #B8B8B8;
  transform: rotate(45deg);
}

.window {
  display: flex;
  justify-content: space-between;
  width: 278px;
  height: 56px;
  padding-top: 16px;
  padding-bottom: 16px;
  padding-left: 24px;
  padding-right: 24px;
  position: fixed;
  top: 75px;
  right: 0;
  background: #FFFFFF;
  border: 1px solid #E3E3E3;
  box-sizing: border-box;
  box-shadow: 0px 2px 12px -2px rgba(21, 21, 21, 0.15);
  border-radius: 12px;
  z-index: 3001;
}
.window__text {
  font-size: 15px;
}
@media (max-width: 767px) {
  .window {
    top: 65px;
  }
}

.map {
  height: 320px;
  overflow: hidden;
  width: 100%;
  position: relative;
}

/* #m1300 */
/* #m1200 */
/* #m1100 */
/* #m900 */
/* #m800 */
/* #m700 */
/* #m600 */
/* #m500 */
/* #m400 */
/* #m400 */
/* #m300 */
/* ---------------- order ---------------- */
.order {
  display: flex;
  flex-direction: column;
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 92px;
}
.order__top-wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.order__body {
  display: flex;
  flex-direction: column;
}
.order__title {
  justify-content: flex-start;
  padding-bottom: 30px;
  border-bottom: 1px solid #E3E3E3;
  font-size: 32px;
  font-family: "interbold", sans-serif;
}
.order__subtitle {
  margin-bottom: 24px;
  font-family: "interbold", sans-serif;
  font-size: 24px;
}
.order__form {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 24px;
  margin-bottom: 30px;
  border-bottom: 1px solid #E3E3E3;
}
.order__form--sb {
  justify-content: space-between;
}
.order__input-wrapper {
  margin-bottom: 15px;
  font-size: 13px;
  color: #B8B8B8;
  width: 48%;
}
.order__input-wrapper .input {
  width: 100%;
  margin-top: 5px;
  border-radius: 6px;
}
.order__input-wrapper input::placeholder {
  color: #B8B8B8;
}
.order__subtitle-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}
.order__subtitle-wrapper .order__subtitle {
  margin-bottom: 0;
}
.order__subtitle-text {
  color: #B8B8B8;
}
.order__form--column {
  flex-direction: column;
}
.order__form-wrapper {
  display: flex;
  flex-wrap: wrap;
}
.order__form-wrapper .wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  justify-content: space-between;
}
.order__form-wrapper .wrap .order__input-wrapper {
  margin: 0;
}
.order__form-wrapper--delivery {
  display: none;
}
.order__form-wrapper--delivery.active {
  display: block;
}
.order__form-wrapper:not(:last-child) {
  margin-bottom: 24px;
}
.order__textarea {
  height: 220px;
  padding-top: 15px;
  padding-bottom: 15px;
  resize: none;
}
.order__textarea::placeholder {
  color: #B8B8B8;
}
.order__input-wrapper--big {
  width: 100%;
}
.order__input-wrapper--middle {
  width: 310px;
}
.order__input-wrapper--middle:not(:last-child) {
  margin-right: 20px;
}
.order__input-wrapper--small {
  width: 115px;
}
.order__form--sb .order__input-wrapper {
  width: 48%;
}
.order__input-wrapper--small:not(:last-child) {
  margin-right: 20px;
}
.order__bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.order__price {
  font-family: "interbold", sans-serif;
  font-size: 20px;
}
.order__button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  width: 200px;
  height: 48px;
  background: #4ACE3F;
  box-shadow: 0px 2px 8px rgba(74, 206, 63, 0.3);
  border-radius: 6px;
  color: #fff;
}
.order__button:hover {
  background: #3BA532;
}
.order__empty {
  display: none; /* flex */
  justify-content: center;
  align-items: center;
  height: 600px;
  width: 100%;
}
.order__empty-text {
  max-width: 153px;
  color: #B8B8B8;
}

/* #m1300 */
/* #m1200 */
/* #m1100 */
/* #m900 */
/* #m800 */
/* #m700 */
@media (max-width: 767px) {
  .order__input-wrapper--middle {
    width: 100%;
  }
  .order__input-wrapper--small {
    width: 43%;
  }
  .order__form--checkbox {
    flex-direction: column;
  }
  .order__form--sb {
    flex-direction: column;
  }
  .order__title {
    font-size: 24px;
  }
  .order__subtitle {
    font-size: 18px;
  }
  .order__subtitle-text {
    font-size: 13px;
  }
  .order__form-wrapper--delivery {
    justify-content: space-between;
  }
  .order__input-wrapper--small:last-child {
    margin-right: 20px;
  }
  .order__form--sb .order__input-wrapper {
    width: 100%;
  }
}
/* #m600 */
/* #m500 */
@media (max-width: 559px) {
  .order .order__top-wrapper {
    margin-bottom: 12px;
  }
  .order .breadcrumbs {
    display: none;
  }
  .order__subtitle-wrapper {
    flex-direction: column;
    align-items: flex-start;
  }
  .order__bottom {
    flex-direction: column;
  }
  .order__price {
    margin-bottom: 16px;
  }
  .order__button {
    width: 100%;
  }
}
/* #m400 */
/* #m400 */
/* #m300 */
/* ---------------- Message ---------------- */
.message {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 250px;
  margin-bottom: 250px;
}
.message__image {
  margin-bottom: 40px;
}
.message__title {
  margin-bottom: 24px;
  font-family: "interbold", sans-serif;
  font-size: 32px;
}
.message__text {
  margin-bottom: 32px;
  text-align: center;
}
.message__button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 182px;
  height: 48px;
  background: #4ACE3F;
  box-shadow: 0px 2px 8px rgba(74, 206, 63, 0.3);
  border-radius: 6px;
  color: #fff;
}

/* #m1300 */
/* #m1200 */
/* #m1100 */
/* #m900 */
/* #m800 */
/* #m700 */
/* #m600 */
/* #m500 */
/* #m400 */
/* #m400 */
/* #m300 */
/* ---------------- FAQ ---------------- */
.faq {
  display: flex;
  flex-direction: column;
  max-width: 630px;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 120px;
  margin-top: 40px;
}
.faq__title {
  justify-content: flex-start;
  margin-bottom: 24px;
  font-family: "interbold", sans-serif;
  font-size: 32px;
}
.faq__text {
  font-size: 18px;
}
.faq__text:not(:last-child) {
  margin-bottom: 20px;
}

/* #m1300 */
/* #m1200 */
/* #m1100 */
/* #m900 */
/* #m800 */
/* #m700 */
@media (max-width: 767px) {
  .faq__title {
    margin-bottom: 12px;
    font-size: 24px;
  }
  .faq__text {
    font-size: 15px;
  }
}
/* #m600 */
/* #m500 */
/* #m400 */
/* #m400 */
/* #m300 */
@font-face {
  font-family: "interbold";
  src: url("/fonts/inter-bold-webfont.woff2") format("woff2"), url("/fonts/inter-bold-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "interregular";
  src: url("/fonts/inter-regular-webfont.woff2") format("woff2"), url("/fonts/inter-regular-webfont.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}