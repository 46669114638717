/* ---------------- Quantity-selector ---------------- */

.quantity-selector {

    display: flex;
    flex-direction: column;
    align-items: center;

    $width: 66px;
    $height: 30px;
    $button-width: 32px;

    width: 32px;
    height: 72px;

    $psize: 10px;

    padding-top: $psize;
    padding-bottom: $psize;

    border: 1px solid $c-light-grey;
    border-radius: 6px;

    &__button-wrapper {
        display: flex;
        flex-direction: column-reverse;
        align-items: center;

        min-width: 35%;
        height: 100%;

        //        border-left: 1px solid $c-border;
    }

    &__button-wrapper > &__button:first-child {
        //       border-bottom: 1px solid $c-border;
        box-sizing: content-box;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 50%;

        position: relative;

        font-size: 14px;
        //       color: $c-text-2;
        line-height: 1;
    }

    &__input {
        flex-grow: 1;

        height: 100%;

        //       font-family: $font-m;
        font-size: 13px;
        //       color: $c-text-2;

        text-align: center;

        transition: background-color 0.25s ease-out 0s;
    }

    $plus-width: 8px;
    $plus-height: 2px;

    .pm {
        width: $plus-width;
        height: $plus-width;
    }

    .minus, .plus {
        background-color: unset;
    }

    .minus:after, .plus:after, .plus:before {
        background-color: $c-yellow;

        transition: none;
    }

    .minus:after {
        width: $plus-width;
        height: 2px;
    }

    .plus:after {
        width: $plus-width;
        height: $plus-height;
    }

    .plus:before {
        width: $plus-height;
        height: $plus-width;
    }

    .error {
        //       background-color: $c-coral;
    }
}

.quantity-selector /* --card */ {

    &--card {
        flex-direction: row;
        height: 48px;
        width: 72px;


        border: unset;
        overflow: hidden;
    }

    &--card &__button-wrapper {
        display: flex;
        flex-direction: column-reverse;
        justify-content: space-between;
        height: 48px;
    }

    &--card .minus:after, &--card .plus:after, &--card .plus:before {
        background: white;
    }

    &--card &__input {
        color: #fff;
        flex-grow: unset;
        height: 48px;
        max-width: 47px;
        width: 100%;

        border-left: 1px solid #80dd78;
        border-right: 1px solid #80dd78;
    }

    &--card .pm {
        height: 23px;
    }

    &--card .plus {
        width: 100%;
        border-bottom: 1px solid #80dd78;
        border-radius: unset;
    }
}

.quantity-selector /* --row */ {

    &--row {
        width: 122px;
        height: 44px;
    }

    &--row &__button-wrapper {
        flex-direction: row;
        width: 100%;
    }
}

.quantity-selector /* --product */ {

    &--product {
        display: none;
        width: 48%;
    }

    &--v2 &__link {

    }
}


@media (max-width: 1236px) {

    .quantity-selector {

        &--card {
            width: 57px;
        }

        &--card &__input {
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}

@media (max-width: 860px) {

    .quantity-selector {

        &--card {
            display: none;
        }
    }

    .quantity-selector {

        &--product {
            display: flex;
            height: 44px;
        }

        &--product &__button-wrapper {
            flex-direction: row;
            width: 100%;
            $psize2: 16px;

            padding-left: $psize2;
            padding-right: $psize2;
        }

        &--product &__button {
            height: 100%;
            width: 25%;
        }

    }
}

@media (max-width: 620px) {


    .quantity-selector {

        &--row {
            width: 32px;
            height: 64px;


        }

        &--row &__button-wrapper {
            flex-direction: column-reverse;
        }
    }
}
