/* ---------------- Slider ---------------- */

.slider {
    width: 100%;
    height: 600px;
    // margin-bottom: 50px;

    position: relative;

    background-color: #F8F8F8;

    // visibility: hidden;
    // overflow: hidden;

    &__sale {
        position: absolute;
        right: 40px;
        top: -20px;
        z-index: 10;
    }

    > ul {
        width: 100%;
    }

    &__item {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 100%;
        height: 100%;

        position: absolute;
        top: 0;

        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover; // contain cover 100%;
        //   background-size: 100%; // contain cover 100%;

        opacity: 0;
        overflow: hidden;

        transition: opacity 0.5s ease-in-out 0s;

        text-align: center;
    }

    &__item.active {
        opacity: 1;
        z-index: 1;
    }

    &__link {
        width: 100%;
        height: 100%;
        position: absolute;
    }

    &__info-block {
        display: flex;
        flex-direction: column;
        // justify-content: space-around;
        align-items: flex-start;
        justify-content: flex-end;

        width: 700px;
        height: 46%;

        position: absolute;
        @include absolute-vertical-center2();
        @include absolute-center2();

        left: -35%;

        font-family: $font-r;
        color: #333333;
        letter-spacing: 1px;
        line-height: 1.4;

        text-align: center;

        z-index: 1;
    }

    &__title {
        margin-bottom: 32px;
        font-family: $font-b;
        font-size: 64px;
        color: white;

        text-transform: uppercase;
        text-align: left;
    }

    &__text {
        max-width: 200px;
        margin-bottom: 32px;
        font-size: 24px;
        color: white;
        text-align: left;
    }

    &__button {
        width: 40px;
        height: 40px;
        background: $c-yellow;
        border-radius: 50%;
        position: relative;
    }

    &__button:after {
        content: '';

        width: 10px;
        height: 10px;

        position: absolute;
        @include absolute-center2();
        @include absolute-vertical-center2();

        border-left: 1px solid #fff;
        border-bottom: 1px solid #fff;

        transform: rotate(45deg);
    }

    &__button--right:after {
        transform: rotate(-135deg);
    }

    &__arrows-block {
        display: flex;
        align-items: flex-end;
        height: 70px;

        position: absolute;
        left: 50%;
        bottom: 0;

        transform: translateX(-50%);
        z-index: 2;
    }

    &__arrow {
        display: flex;
        align-items: center;

        width: 75px;
        height: 68px;

        background-color: #ffffff;

        cursor: pointer;

        opacity: 1;
        transition: filter 0.2s ease;
    }

    &__arrow--left {
        justify-content: flex-end;
        padding-right: 10px;
    }

    &__arrow--right {
        padding-left: 10px;
    }

    &__arrow-svg {
        fill: black;
        fill-rule: evenodd;

        $size: 33px;

        width: $size;
        height: $size;
    }

    &__arrow:hover {
        filter: brightness(90%);
    }

    &__navigation {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 200px;
    }

    &__controls {
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 60px;

        font-size: 0;
        text-align: center;

        z-index: 1;
    }

    &__toggle {

        $size: 12px;

        width: $size;
        height: $size;

        position: relative;

        $color: #333333;

        border-radius: 50%;
        background-color: #a7a7a9;

        transition: all 0.3s ease-out;
    }

    &__toggle:last-child {
        margin-right: 0;
    }

    &__toggle:only-child {
        display: none;
    }

    &__toggle.active {
        background-color: $c-yellow;
    }
}

// 1220 пикселей и меньше
@media (max-width: 1220px) {

    .slider {

        height: 300px;

        &__item {
            max-width: unset;
            align-items: flex-start;
            height: 300px;
        }

        &__title {
            width: fit-content;
            margin-bottom: 20px;
            font-size: 32px;
        }

        &__text {
            margin-bottom: 20px;
            font-size: 18px;
            text-align: left;
        }

        &__info-block {
            width: auto;
            left: 0;
        }
    }
}

/* #m1300 */
@media (max-width: 1366px) {

}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

}

/* #m900 */
@media (max-width: 990px) {

}

/* #m800 */
@media (max-width: 860px) {


}

/* #m700 */
@media (max-width: 768px) {

    .slider {

        height: 220px;
        margin-bottom: 30px;
        position: relative;

        &__button {
            display: none;
        }

        &__navigation {
            justify-content: center;
            position: absolute;
            bottom: -20px;
            @include absolute-center2();
        }

        &__item {
            height: 220px;
            background-size: cover;
        }

        &__title {
            font-size: 32px;
        }

        &__text {
            font-size: 16px;
        }

        &__info-block {
            max-width: unset;
            justify-content: flex-start;
            padding-left: 20px;
            padding-top: 24px;
            height: auto;
            left: 0;
        }
        &__sale {
            display: none;
        }
    }
}

/* #m600 */
@media (max-width: 620px) {

}

/* #m500 */
@media (max-width: 560px) {


}

/* #m400 */
@media (max-width: 480px) {


}

/* #m400 */
@media (max-width: 440px) {


}

/* #m300 */
@media (max-width: 360px) {

}
