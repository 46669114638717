/* ---------------- Select ---------------- */

.select {

    margin-left: 20px;
    color: $c-default;
    position: relative;

    &:after {
        content: '';

        width: 15px;
        height: 15px;

        border-bottom: 1px solid $c-grey;
        border-left: 1px solid $c-grey;

        position: absolute;
        top: 20%;
        right: 21px;

        transform: rotate(-45deg);
    }

    &__input {
        width: 335px;
        height: 48px;
        padding: 15px;
        appearance: none;
        -webkit-appearance: none;

        border: 1px solid $c-light-grey;
        box-sizing: border-box;
        border-radius: 6px;
    }

    option {
        border: 1px solid $c-light-grey;
        box-sizing: border-box;
    }
}

/* #m1300 */
@media (max-width: 1366px) {

}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

}

/* #m900 */
@media (max-width: 989px) {

}

/* #m800 */
@media (max-width: 859px) {

}

/* #m700 */
@media (max-width: 767px) {

    .select {

        width: 100%;
        margin-left: 0;

        &__input {
            width: 100%;
        }
    }
}

/* #m600 */
@media (max-width: 619px) {

}

/* #m500 */
@media (max-width: 559px) {

}

/* #m400 */
@media (max-width: 479px) {

}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 359px) {

}
