/* ---------------- Filter ---------------- */

.filter {

    display: flex;
    justify-content: space-between;
    align-items: center;

    &__column {
        display: flex;
        align-items: center;
    }

    &__column--1 {
        align-items: baseline;
    }

    &__title {
        margin-right: 20px;
        font-size: 32px;
        font-weight: bold;
    }

    &__text {
        font-size: 15px;
        color: $c-grey;
    }

}


/* #m1300 */
@media (max-width: 1366px) {

}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

}

/* #m900 */
@media (max-width: 989px) {

}

/* #m800 */
@media (max-width: 859px) {

}

/* #m700 */
@media (max-width: 767px) {

    .filter {

        flex-direction: column;
        align-items: flex-start;

        &__column--1 {
            justify-content: space-between;
            //  max-width: 375px;
            width: 100%;
            margin-bottom: 16px;
        }

        &__column--2 {
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
        }

        &__column--2 &__text {
            margin-bottom: 10px;
        }
    }
}

/* #m600 */
@media (max-width: 619px) {

}

/* #m500 */
@media (max-width: 559px) {

}

/* #m400 */
@media (max-width: 479px) {

}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 359px) {

}
