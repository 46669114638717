/* ---------------- Categories ---------------- */

.categories {

    display: flex;
    flex-wrap: wrap;
    $psize: 60px;

    padding-top: $psize;
    padding-bottom: $psize;
    //  margin-right: -25px;

    &__item {
        display: flex;
        width: 23%;
        height: 246px;
        margin-bottom: 25px;
        margin-right: 25px;

        position: relative;
        border: 1px solid #E3E3E3;
        box-sizing: border-box;
        border-radius: 12px;
        overflow: hidden;
        color: $c-default;
    }

    &__item:hover {
        color: $c-yellow;
        text-decoration: none;
    }


    &__item-title {
        margin-left: 24px;
        margin-top: 24px;
        font-family: $font-b;
        font-size: 20px;
        z-index: 1;
    }

    &__item-image {
        transition: .1s all linear;
        position: absolute;
        width: 75%;
        height: 75%;
        bottom: 0;
        right: 0;
        border-radius: 12px;
        object-fit: contain;
    }

    &__item:hover &__item-image {
        transform: scale(1.2);
    }
}

/* #m1300 */
@media (max-width: 1366px) {

    .categories {

        &__item {
            width: 30%;
        }
    }
}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

}

/* #m900 */
@media (max-width: 989px) {


}

/* #m800 */
@media (max-width: 859px) {

}

/* #m700 */
@media (max-width: 767px) {

    .categories {

        justify-content: space-between;
        margin-right: 0;
        padding-top: 25px;
        padding-left: 20px;
        padding-right: 20px;
        padding-bottom: 20px;

        &__item {
            width: 47%;
            height: 130px;
            margin-right: 0;
        }

        &__item-image {
            width: 80%;

        }

        &__item-title {
            margin-top: 16px;
            margin-left: 15px;
            font-size: 15px;
        }
    }
}

/* #m600 */
@media (max-width: 619px) {

}

/* #m500 */
@media (max-width: 559px) {

}

/* #m400 */
@media (max-width: 479px) {

}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 359px) {

}
