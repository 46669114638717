/* ---------------- Header ---------------- */
.wrapper{
    margin-top: 64px;
}
.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 99;
    $psize: 12px;
    background: #fff;
    padding-top: $psize;
    padding-bottom: $psize;
    border-bottom: 1px solid $c-light-grey;

    &__wrapper {
        display: flex;
        justify-content: space-between;
        position: relative;
    }

    &__columns {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 54%;
        position: relative;
    }

    &__column-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 44%;
        &.phone{
            justify-content: flex-end;
            .header__contact-mobile{
                margin-right: 30px;
            }
        }
    }

    &__column--4 {
        display: flex;
    }

    &__navigation-wrapper {
        margin-bottom: 0;
        display: initial;
        position: relative;
    }

    &__navigation-wrapper:after {
        content: url("/svg/navigation.svg");
        position: absolute;
        left: 16px;
        top: 50%;
        transform: translateY(calc(-50% + 2px));
    }

    &__navigation {

        $psize: 11px;

        padding-top: $psize;
        padding-bottom: $psize;
        padding-left: 40px;

        border: 1px solid #E3E3E3;
        box-sizing: border-box;
        border-radius: 6px;
    }

    &__navigation::placeholder {
        color: $c-grey;
    }

    &__navigation-window {
        display: none;
        flex-direction: column;
        width: 335px;
        $psize2: 16px;

        padding-left: $psize2;
        padding-right: $psize2;

        $psize: 4px;

        padding-top: $psize;
        padding-bottom: $psize;

        position: absolute;
        top: 78px;
        left: 33px;
        z-index: 10;
        background: #FFFFFF;
        border-radius: 6px;

        box-shadow: 0px 2px 12px -2px rgba(21, 21, 21, 0.15);
        &_mobile{
            position: fixed;
            top: 71px;
            left: 0;
            z-index: 100;
            width: 100%;
            height: 100vh;
            display: none;
            background: rgba(21, 21, 21, 0.5);
            backdrop-filter: blur(8px);
            &.active{
                display: flex;
                align-items: baseline;
            }
            .header__navigation-window_mobile-list{
                position: relative;
                top: 0;
                left: 0;
                margin: 20px;
                background: white;
                border-radius: 6px;
                width: 100%;
                .header__navigation-wrapper{
                    display: block;
                    width: 100%;
                    &:after{
                        left: auto;
                        top: 12px;
                        right: 12px;
                    }
                    input{
                        padding-left: 12px;
                        width: 100%;
                    }
                }
                .header__navigation-item{
                    padding: 0 12px;
                }
            }
        }
    }

    &__navigation-window.active:before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 6px solid white;
        top: -6px;
        @include absolute-center2();
    }

    &__navigation-item {
        display: flex;
        align-items: center;
        height: 42px;
        color: $c-grey;
    }

    &__navigation-item span {
        color: $c-default;
        margin-right: 5px;
    }

    &__navigation-item:not(:last-child) {
        border-bottom: 1px solid $c-light-grey;
    }

    &__navigation-window.active {
        display: flex;
    }


    &__contact {
        position: relative;
        font-size: 15px;
        font-weight: bold;
        color: $c-default;
        margin-right: 15px;
    }

    &__contact:after {
        content: url("/svg/phone.svg");
        position: absolute;
        left: -36px;
        @include absolute-vertical-center2();
        top: -4px;
    }
    &__halal_img{
        position: absolute;
        bottom: -85px;
        right: 15px;
        z-index: 101;
        width: 60px;
        img{
            width: 100%;
        }
        @media(max-width: 989px){
            right: 27px;
            width: 35px;
            bottom: -60px;
        }
    }
    &__logo{
        display: flex;
        align-items: center;
        //transform: scale(1.2);
    }
    &__logo-mobile {
        display: none;
    }

    &__navigation-mobile {
        display: none;
    }

    &__contact-mobile {
        display: none;
    }


}

/* #m1300 */
@media (max-width: 1366px) {

}

/* #m1200 */
@media (max-width: 1220px) {

    .header {

        &__columns {
            width: 67%;
        }
    }
}

/* #m1100 */
@media (max-width: 1100px) {

}

/* #m900 */
@media (max-width: 989px) {
    .wrapper{
        margin-top: 57px;
    }
    .header {
        &__navigation-window{
            display: none!important;
        }
        &__logo {
            display: none;
        }

        &__columns {
            width: 100%;
        }
        &__column-wrapper {
            width: 20%;
        }

        &__navigation-wrapper {
            display: none;
        }

        &__logo-mobile {
            display: flex;
        }

        &__navigation-mobile {
            display: flex;
        }

        &__contact-mobile {
            display: flex;
        }

        &__contact {
            display: none;
        }

    }
}

/* #m800 */
@media (max-width: 859px) {

}

/* #m700 */
@media (max-width: 767px) {

    .header{

        &__column--1 {
            display: flex;
            align-items: flex-end;
            height: 14px;
        }
    }
}

/* #m600 */
@media (max-width: 619px) {

}

/* #m500 */
@media (max-width: 559px) {

    .header{

        &__column-wrapper {
            width: 25%;
        }
    }
}

/* #m400 */
@media (max-width: 479px) {

}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 359px) {

}
