/* ---------------- Footer ---------------- */

.footer {

    display: flex;
    flex-direction: column;

    &__columns {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;

        padding-top: 20px;
    }

    &__column {
        display: flex;
        flex-direction: column;
        margin-bottom: 20px;
        margin-right: 20px;

    }

    &__column-title {
        margin-bottom: 11px;
        font-size: 13px;
        color: $c-grey;
    }

    &__column-item {
        font-size: 15px;
        color: $c-default;
        //font-weight: bold;
    }

    &__column-item:not(:last-child) {
        margin-bottom: 10px;
    }
}

/* #m1300 */
@media (max-width: 1366px) {

}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

}

/* #m900 */
@media (max-width: 989px) {

    .footer {

        &__column {
            margin-bottom: 24px;
        }
        &__column:last-child{
            flex: 1 1 100%;
        }
        &__column:nth-child(1) {
            order: 2;
        }

        &__column:nth-child(2) {
            order: 1;
        }

        &__column:nth-child(3) {
            order: 6;
        }

        &__column:nth-child(4) {
            order: 3;
        }

        &__column:nth-child(5) {
            order: 7;
        }

        &__column:nth-child(6) {
            order: 5;
        }

        &__column:nth-child(7) {
            order: 8;
        }

    }
}

/* #m800 */
@media (max-width: 859px) {

}

/* #m700 */
@media (max-width: 767px) {

}

/* #m600 */
@media (max-width: 619px) {


}

/* #m500 */
@media (max-width: 559px) {

}

/* #m400 */
@media (max-width: 479px) {

    .footer {

        &__column:nth-child(3) {
            width: 100%;
        }
    }
}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 359px) {

}
