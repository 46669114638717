/* ---------------- Mobile button ---------------- */

.mobile-button {

    $button-width: 26px;
    $button-height: 22px;

    $button-margin-right: 12px;

    $button-parts-height: 2px;
    $button-color: white;

    $button-parts-shift: ($button-height + ($button-parts-height * 3)) / 3;

    // display: flex;
    display: none;

    justify-content: center;
    align-items: center;

    cursor: pointer;

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: center;

        width: $button-width;
        height: $button-height;

        margin-right: $button-margin-right;

        position: relative;
    }

    &__text {
        font-family: $font-r;
        font-size: 22px;
        color: $button-color;

        letter-spacing: 1px;
        text-transform: uppercase;
    }

    &__button, &__button:before, &__button:after {
        display: block;
        content: '';

        height: $button-parts-height;
        width: 100%;

        position: absolute;

        background: $button-color;
        border-radius: 1px;

        cursor: pointer;

        transition: all 300ms ease-in-out;
    }

    &__button {
        @include absolute-vertical-center2();
    }

    &__button:after {
        top: -$button-parts-shift;
    }

    &__button:before {
        bottom: -$button-parts-shift;
    }

    &.active &__button {
        background-color: transparent;
    }

    &.active &__button:before,
    &.active &__button:after {
        top: 0;
    }

    &.active &__button:before {
        transform: rotate(45deg);
    }

    &.active &__button:after {
        transform: rotate(-45deg);
    }
}

@media (max-width: 989px) {

    .mobile-button {
        display: flex;
    }
}

@media (max-width: 767px) {

}

@media (max-width: 619px) {

}

@media (max-width: 479px) {

}
