@font-face {
    font-family: 'interbold';
    src: url('/fonts/inter-bold-webfont.woff2') format('woff2'),
    url('/fonts/inter-bold-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'interregular';
    src: url('/fonts/inter-regular-webfont.woff2') format('woff2'),
    url('/fonts/inter-regular-webfont.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}