/* ---------------- order ---------------- */

.order {
    display: flex;
    flex-direction: column;
    max-width: 850px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 92px;

    &__top-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
    }

    &__body {
        display: flex;
        flex-direction: column;
    }

    &__title {
        justify-content: flex-start;
        padding-bottom: 30px;
        border-bottom: 1px solid $c-light-grey;
        font-size: 32px;
        font-family: $font-b;
    }

    &__subtitle {
        margin-bottom: 24px;
        font-family: $font-b;
        font-size: 24px;
    }

    &__form {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 24px;
        margin-bottom: 30px;
        border-bottom: 1px solid $c-light-grey;
    }

    &__form--sb {
        justify-content: space-between;
    }

    &__input-wrapper {
        margin-bottom: 15px;
        font-size: 13px;
        color: $c-grey;
        width: 48%;
    }


    &__input-wrapper .input {
        width: 100%;
        margin-top: 5px;
        border-radius: 6px;
    }

    &__input-wrapper input::placeholder {
        color: $c-grey;
    }

    &__subtitle-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 24px;
    }

    &__subtitle-wrapper &__subtitle {
        margin-bottom: 0;
    }

    &__subtitle-text {
        color: $c-grey;
    }

    &__form--column {
        flex-direction: column;
    }

    &__form-wrapper {
        display: flex;
        flex-wrap: wrap;
        .wrap{
            display: flex;
            flex-wrap: wrap;
            gap: 6px;
            justify-content: space-between;
            .order__input-wrapper{
                margin: 0;
            }
        }
    }

    &__form-wrapper--delivery{
        display: none;
        &.active{
            display: block;
        }
    }

    &__form-wrapper:not(:last-child) {
        margin-bottom: 24px;
    }

    &__textarea {
        height: 220px;
        $psize: 15px;

        padding-top: $psize;
        padding-bottom: $psize;
        resize: none;
    }

    &__textarea::placeholder {
        color: $c-grey;
    }

    &__input-wrapper--big {
        width: 100%
    }

    &__input-wrapper--middle {
        width: 310px;
    }

    &__input-wrapper--middle:not(:last-child) {
        margin-right: 20px;
    }

    &__input-wrapper--small {
        width: 115px;
    }

    &__form--sb &__input-wrapper {
        width: 48%;
    }

    &__input-wrapper--small:not(:last-child) {
        margin-right: 20px;
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__price {
        font-family: $font-b;
        font-size: 20px;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;

        margin-top: 20px;

        width: 200px;
        height: 48px;

        background: $c-yellow;
        box-shadow: 0px 2px 8px rgba(74, 206, 63, 0.3);
        border-radius: 6px;
        color: #fff;
    }

    &__button:hover {
        background: #3BA532;
    }

    &__empty {
        display: none; /* flex */
        justify-content: center;
        align-items: center;
        height: 600px;
        width: 100%;
    }

    &__empty-text {
        max-width: 153px;
        color: $c-grey;
    }
}

/* #m1300 */
@media (max-width: 1366px) {

}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

}

/* #m900 */
@media (max-width: 989px) {

}

/* #m800 */
@media (max-width: 859px) {

}

/* #m700 */
@media (max-width: 767px) {

    .order {

        &__input-wrapper--middle {
            width: 100%;
        }

        &__input-wrapper--small {
            width: 43%;
        }

        &__form--checkbox {
            flex-direction: column;
        }

        &__form--sb {
            flex-direction: column;
        }

        &__title {
            font-size: 24px;
        }

        &__subtitle {
            font-size: 18px;
        }

        &__subtitle-text {
            font-size: 13px;
        }

        &__form-wrapper--delivery {
            justify-content: space-between;

        }

        &__input-wrapper--small:last-child {
            margin-right: 20px;
        }

        &__form--sb &__input-wrapper {
            width: 100%;
        }
    }
}

/* #m600 */
@media (max-width: 619px) {

}

/* #m500 */
@media (max-width: 559px) {

    .order {
        .order__top-wrapper{
            margin-bottom: 12px;
        }
        .breadcrumbs{
            display: none;
        }
        &__subtitle-wrapper {
            flex-direction: column;
            align-items: flex-start;
        }

        &__bottom {
            flex-direction: column;
        }

        &__price {
            margin-bottom: 16px;
        }

        &__button {
            width: 100%;
        }
    }
}

/* #m400 */
@media (max-width: 479px) {

}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 359px) {

}
