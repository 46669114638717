/* ---------------- FAQ ---------------- */

.faq {
    display: flex;
    flex-direction: column;
    max-width: 630px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 120px;
    margin-top: 40px;

    &__title {
        justify-content: flex-start;
        margin-bottom: 24px;
        //  margin-top: 20px;
        font-family: $font-b;
        font-size: 32px;
    }

    &__text {
        font-size: 18px;
    }

    &__text:not(:last-child) {
        margin-bottom: 20px;
    }
}

/* #m1300 */
@media (max-width: 1366px) {

}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

}

/* #m900 */
@media (max-width: 989px) {

}

/* #m800 */
@media (max-width: 859px) {

}

/* #m700 */
@media (max-width: 767px) {

    .faq {

        &__title {
            margin-bottom: 12px;
            font-size: 24px;
        }

        &__text {
            font-size: 15px;
        }
    }
}

/* #m600 */
@media (max-width: 619px) {

}

/* #m500 */
@media (max-width: 559px) {

}

/* #m400 */
@media (max-width: 479px) {

}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 359px) {

}
