/* ---------------- Message ---------------- */

.message {
    display: flex;
    flex-direction: column;
    align-items: center;

    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 250px;
    margin-bottom: 250px;

    &__image {
        margin-bottom: 40px;
    }

    &__title {
        margin-bottom: 24px;
        font-family: $font-b;
        font-size: 32px;
    }

    &__text {
        margin-bottom: 32px;
        text-align: center;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;

        width: 182px;
        height: 48px;

        background: $c-yellow;
        box-shadow: 0px 2px 8px rgba(74, 206, 63, 0.3);
        border-radius: 6px;
        color: #fff;
    }
}

/* #m1300 */
@media (max-width: 1366px) {

}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

}

/* #m900 */
@media (max-width: 989px) {

}

/* #m800 */
@media (max-width: 859px) {

}

/* #m700 */
@media (max-width: 767px) {

}

/* #m600 */
@media (max-width: 619px) {

}

/* #m500 */
@media (max-width: 559px) {

}

/* #m400 */
@media (max-width: 479px) {

}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 359px) {

}
