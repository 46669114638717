/* ---------------- Cart ---------------- */

.cart {
    display: flex;
    align-items: center;
    position: relative;


    &__button {
        margin-right: 18px;
        position: relative;
        cursor: pointer;
    }

    &__value {

        display: flex;
        justify-content: center;
        align-items: center;

        width: 16px;
        height: 16px;

        position: absolute;
        right: -8px;
        top: -6px;

        background: $c-yellow;
        border-radius: 50%;

        font-size: 12px;
        color: #fff;
    }

    &__text {
        font-size: 15px;
        font-weight: bold;
    }

    &__window {
        display: flex;
        flex-direction: column;

        width: 420px;
        max-height: calc(100vh - 143px);
        overflow: auto;

        padding-top: 12px;
        padding-bottom: 16px;
        $psize2: 20px;

        padding-left: $psize2;
        padding-right: $psize2;
        border: 1px solid $c-light-grey;
        background: #fff;
        box-sizing: border-box;
        border-radius: 12px;

        box-shadow: 0 2px 12px -2px rgba(21, 21, 21, 0.15);
        z-index: 100;
        transition: all 0.2s ease-out;

    }

    .header__column--4 + & {
        position: absolute;
        top: 200%;
        right: 0;

        visibility: hidden;
        opacity: 0;
    }

    .header__column--4:hover + & {
        opacity: 1;
        visibility: visible;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 12px;
        border-bottom: 1px solid $c-light-grey;
    }

    &__item:not(:last-child) {
        margin-bottom: 12px;
    }

    &__item-image {
        width: 90px;
        height: 72px;
        overflow: hidden;
        border: 1px solid $c-light-grey;
        box-sizing: border-box;
        border-radius: 12px;
    }

    &__item-image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
    }

    &__item-body {
        display: flex;
        flex-direction: column;
        width: 206px;
    }

    &__item-title {
        font-size: 15px;
        font-weight: bold;
        a{
            color: #000;
        }
    }

    &__item-wrapper {
        display: flex;
        align-items: center;
    }

    &__item-price {
        font-size: 15px;
        margin-right: 8px;
    }

    &__item-count {
        font-size: 15px;
        color: $c-grey;
    }

    &__button-delete {
        position: relative;
        width: 16px;
        height: 16px;
        overflow: hidden;
    }

    &__button-delete:after, &__button-delete:before {
        content: '';
        width: 16px;
        height: 1.5px;

        position: absolute;
        @include absolute-vertical-center2();
        @include absolute-center2();

        transform: rotate(45deg);
        background: $c-grey;
    }

    &__button-delete:before {
        transform: rotate(-45deg);
    }

    &__bottom {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &__total-price {
        display: flex;
        justify-content: center;
        $psize: 16px;

        padding-top: $psize;
        padding-bottom: $psize;
        font-size: 18px;
        font-weight: bold;
    }

    &__button-buy {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 48px;

        font-size: 15px;
        color: #fff;
        background: $c-yellow;
        box-shadow: 0px 2px 8px rgba(74, 206, 63, 0.3);
        border-radius: 6px;
    }

    &__button-buy:hover {
        background: #3BA532;
    }

    &__empty {
        display: none;
        justify-content: center;
        align-items: center;
        height: 84px;
    }

    &__empty.active {
        display: flex;
    }

    /* После изменения HTML поправить тут на старые стили */
    &__empty-text {
        display: flex;
        align-items: center;
        height: 108px;
        margin-left: auto;
        margin-right: auto;
        max-width: 155px;
        font-size: 15px;
        color: $c-grey;
        text-align: center;
    }

    &__item-price--mobile {
        display: none;
    }
}

.cart /* --order */ {

    &--order {
        flex-direction: column;
        padding-top: 20px;
        margin-bottom: 30px;
    }

    &--order &__item {
        width: 100%;
        padding-bottom: 20px;
    }

    &--order &__item-price {
        font-family: $font-b;
    }

    &--order &__item-image {
        margin-right: 20px;
    }

    &--order &__price--mobile {
        display: none;
        font-family: $font-r;
        font-size: 15px;
    }
}

.cart /* --window */ {

    &--window {
        display: none;
        justify-content: flex-end;
        align-items: flex-start;
        max-width: 1290px;
        width: 100%;
        height: 100%;
        position: absolute;
        @include absolute-center2();
        top: 13px;
        opacity: 0;
        overflow: hidden;
    }

    &--window.active {
        display: flex;
        opacity: 1;
        overflow: visible;
        position: fixed;
        top: 90px;
        z-index: 100;
    }
}

/* #m1300 */
@media (max-width: 1366px) {

    .cart {

        &--window {
            right: 20px;
            left: unset;
        }
    }

}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

    .cart {

        &--window {
            right: 15px;
        }
    }
}

/* #m900 */
@media (max-width: 989px) {

    .cart {

        &__text {
            display: none;
        }
    }

    .cart {

        &--window {
            right: 12px;
        }
    }
}

/* #m800 */
@media (max-width: 859px) {

}

/* #m700 */
@media (max-width: 767px) {

    .cart {

        &__window {
            // width: 100%;
            border-radius: unset;
            right: 0;
            top: 43px;
        }
    }

    .cart {

        &--window &__window {
            border-radius: 12px;
        }
    }
}

/* #m600 */
@media (max-width: 619px) {

    .cart {

        &--window {
            justify-content: center;
            align-items: flex-start;
            width: 100%;
            height: 100%;
            right: 0;
            left: 0;
            top: 0;
            z-index: 10;
            background: rgba(0, 0, 0, 0.6);
        }

        &--window &__window {
            width: 100%;
            border-radius: unset;
        }
    }

    .cart {

        &__item-body {
            flex-grow: 1;
            margin-left: 10px;
            margin-right: 10px;
            border: unset;
        }
    }
}

/* #m500 */
@media (max-width: 559px) {

    .cart {

        &--order &__button-delete {
            //display: none;
            margin-left: 15px;
        }

        &--order &__item-price {
            display: none;
        }

        &--order &__item-price--mobile {
            display: flex;
        }

        &--order &__item-body {
            width: 70%;
        }

        &--order &__item-wrapper {
            width: 87%;
        }
    }

    .cart {

        &__button-delete {
            //display: none;
            margin-left: 15px;
        }
    }
}

/* #m400 */
@media (max-width: 479px) {

    .cart {

        .header__column--4 + & {
            align-items: flex-start;
            width: 106%;
            height: 100vw;
            top: 44px;
            right: -12px;
            z-index: 10;
            background: rgba(0, 0, 0, 0.5);
        }

        &__window {
            width: 100%;
            right: 0;
            border: unset;
            border-top: 1px solid $c-light-grey;
        }


    }
}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 359px) {

}
