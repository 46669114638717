/* ---------------- Misc ---------------- */

.link-back {
    display: flex;
    align-items: center;
    padding-left: 16px;
    font-size: 15px;
    position: relative;
    color: $c-grey;

    &:after {
        content: '';

        width: 10px;
        height: 10px;

        position: absolute;
        left: 0;
        @include absolute-vertical-center2();

        border-bottom: 1px solid $c-grey;
        border-left: 1px solid $c-grey;
        transform: rotate(45deg);
    }
}

.window {
    display: flex;
    justify-content: space-between;
    width: 278px;
    height: 56px;

    $psize: 16px;

    padding-top: $psize;
    padding-bottom: $psize;

    $psize2: 24px;

    padding-left: $psize2;
    padding-right: $psize2;

    position: fixed;
    top: 75px;
    right: 0;

    background: #FFFFFF;
    border: 1px solid #E3E3E3;
    box-sizing: border-box;
    box-shadow: 0px 2px 12px -2px rgba(21, 21, 21, 0.15);
    border-radius: 12px;
    z-index: 3001;

    &__text {
        font-size: 15px;
    }
    @media (max-width: 767px) {
        top: 65px
    }
}

.map {
    height: 320px;
    overflow: hidden;
    width: 100%;
    position: relative;
}

.container.cart--window{
    //   position: relative;
}

//iframe {
//    height: 470px !important;
//    margin-top: -150px !important;
//    padding-bottom: 150px;
//}

/* #m1300 */
@media (max-width: 1366px) {

}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

}

/* #m900 */
@media (max-width: 989px) {

}

/* #m800 */
@media (max-width: 859px) {

}

/* #m700 */
@media (max-width: 767px) {

}

/* #m600 */
@media (max-width: 619px) {

}

/* #m500 */
@media (max-width: 559px) {

}

/* #m400 */
@media (max-width: 479px) {

}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 359px) {

}
