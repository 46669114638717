/* ---------------- Dropmenu ---------------- */

.dropmenu {

    padding-right: 23px;
    position: relative;
    font-size: 15px;
    font-weight: bold;

    cursor: pointer;
    transition: all 0.2s ease-out;



    &:after {
        content: url("/svg/arrow-down.svg");
        position: absolute;
        right: 0;
        @include absolute-vertical-center2();
    }

    &:before &.active {
        content: '';

        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 6px solid white;

        position: absolute;
        top: 59px;
        left: 20px;
    }


    &__list {
        display: none;

        grid-template-columns: repeat(auto-fill, minmax(165px, 1fr));
        column-gap: 10px;
        align-items: center;

        width: 595px;


        padding: 20px 40px;

        position: absolute;
        left: -277px;
        top: 65px;


        background: white;
        border-radius: 16px;
        z-index: 100;
        transition: all 0.2s ease-out;

        box-shadow: 0 2px 12px -2px rgba(21, 21, 21, 0.15);
    }

    &.active &__list {
        display: grid;
        &:before {
            content: '';
            position: absolute;
            width: 0;
            height: 0;
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            border-bottom: 6px solid white;
            top: -6px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            right: 0;
        }
    }

    &__item {
        display: flex;
        align-items: center;
        width: 33.3%;
        height: 72px;
        .safari_placeholder{
            display: none;
        }
    }

    &__item:hover &__item-text {
        color: $c-yellow;

    }

    &__item:hover {
        text-decoration: none;
    }

    &__item-image {
        margin-right: 13px;
        width: 32px;
        height: 32px;
    }

    &__item-text {
        font-size: 18px;
        font-weight: bold;
        color: $c-default;
    }

    &.active &__list:before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-bottom: 6px solid white;
        top: -6px;
        @include absolute-center2();
    }

}

/* #m1300 */
@media (max-width: 1366px) {

}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

}

/* #m900 */
@media (max-width: 989px) {

    .dropmenu {

        &__list {
            left: 0;
        }

        &.active &__list:before{
            display: none;
        }
    }
}

/* #m800 */
@media (max-width: 859px) {

}

/* #m700 */
@media (max-width: 767px) {

    .dropmenu {
        width: 12px;
        height: 2px;
        padding-right: 0;
        background: $c-default;
        font-size: 0;


        &:after, &:before {
            content: '';
            border: unset;
            background: $c-default;
            width: 24px;
            height: 2px;
            position: absolute;
            top: -13px;
            left: 0;
        }

        &:before {
            top: -12px;
        }

        &.active {
            background: transparent;
        }

        &.active:after, &.active:before {
            @include absolute-vertical-center2();
            @include absolute-center2();
            transform: rotate(45deg);
        }

        &.active:before {
            transform: rotate(135deg);
        }
        &.active &__list{
            display: block!important;
        }
        &__list {
            // flex-grow: 1;
            overflow: auto;
            height: calc(100vh - 57px);
            flex-direction: column;
            width: 100vw;
            margin-left: -12px;
            padding-left: 0;
            border-radius: 0;
            top: 24px;
            //   left: 0;
        }



        &__item {
            width: 100%;
            padding-left: 10px;
            border-bottom: 1px solid $c-light-grey;
            &.safari_placeholder {
                display: flex;
                border: none;
            }
        }
    }
}

/* #m600 */
@media (max-width: 619px) {

}

/* #m500 */
@media (max-width: 559px) {

}

/* #m400 */
@media (max-width: 479px) {

}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 359px) {

}
