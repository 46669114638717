/* ---------------- Card ---------------- */

.card-wrapper {
    display: flex;
    flex-wrap: wrap;
    margin-top: 34px;
    margin-left: -25px;
}

.card {

    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 23.1%;
    height: 422px;
    margin-left: 1.9%;
    margin-bottom: 25px;
    padding-bottom: 20px;

    background: #FFFFFF;

    border: 1px solid $c-light-grey;
    box-sizing: border-box;
    border-radius: 12px;

    z-index: 1;


    &__image {
        width: 100%;
        height: 200px;
        overflow: hidden;
        z-index: 0;
        border-top-right-radius: 12px;
        border-top-left-radius: 12px;
        &-item{
            height: 100%;
        }
    }

    &__image img {
        width: 100%;
        height: 100%;
        object-fit: contain;
        transition: .2s all ease-in-out;
        &:hover{
            transform: scale(1.2);
        }
    }


    &__body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-grow: 1;
        $psize2: 24px;

        padding-left: $psize2;
        padding-right: $psize2;
        padding-bottom: 12px;
    }

    &__link {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        color: $c-default;
    }

    &__description {
        display: flex;
        flex-direction: column;
    }

    &__title {
        margin-bottom: 12px;
        font-size: 18px;
        font-weight: bold;
        color: $c-default;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__text {
        //height: 60px;
        font-size: 15px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    &__product-info {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 12px;
    }

    &__price {
        font-size: 20px;
        font-weight: bold;
    }

    &__count {
        font-size: 15px;
        color: $c-grey;
    }

    &__button {
        display: flex;
        justify-content: space-between;
        align-items: center;

        height: 48px;

        margin-right: 24px;
        margin-left: 24px;

        position: relative;
        background: $c-yellow;

        box-shadow: 0px 2px 8px rgba(74, 206, 63, 0.4);
        border-radius: 6px;
        cursor: pointer;
        overflow: hidden;
        .not_in_cart {
            display: inline;
            color: #fff;
        }

        .in_cart {
            display: none;
            color: #fff;
        }

        &.product_in_cart {
            .not_in_cart {
                display: none;
            }

            .in_cart {
                display: inline;
            }
        }
        &.product_in_cart{
            .card__button-text{
                padding: 0;
            }
            .quantity-selector--card {
                display: none;
            }
        }
    }

    &__button:hover {
        background: #3BA532;
    }

    &__button-text {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-grow: 1;
        height: 100%;
        padding-left: 20px;

        font-size: 15px;
        color: #fff;
        position: relative;

        button {
            display: inline-flex;
            align-items: center;


        }

    }

    &__button-text img {
        width: 18px !important;
        margin-right: 10px;
    }

    //&__button-text:after {
    //    content: url("/svg/cart-white.svg");
    //    position: absolute;
    //    left: -29px;
    //    @include absolute-vertical-center2();
    //}
}

.card /* --big */
{

    &--big {
        flex-direction: row;
        justify-content: space-between;
        width: 100%;
        height: 458px;
        margin-bottom: 200px;
        margin-top: 160px;
        margin-left: 0 !important;
        border: unset;
        position: relative;
    }

    &--big &__image {
        height: 100%;
        width: 50%;
    }

    &--big &__image img {
        width: 100%;
        height: 100%;
    }

    &--big &__body {
        width: 48%;
        height: 100%;
        padding-bottom: 0;
    }

    &--big &__title {
        font-family: $font-b;
        font-size: 32px;
    }

    &--big &__text {
        font-size: 18px;
    }

    &--big &__product-info {
        align-items: center;
        padding-left: 0 !important;
        padding-right: 0 !important;
    }

    &--big &__price {
        font-size: 32px;
    }

    &--big &__count {
        font-size: 18px;
    }

    &--big &__button {
        margin-right: 0;
        margin-left: 0;
    }

    &--big &__button-back {
        position: absolute;
        top: -40px;
        left: 24px;
        font-size: 15px;
        color: $c-default;
    }

    &--big &__button-back:before {
        content: '';

        width: 10px;
        height: 10px;

        position: absolute;
        left: -18px;
        @include absolute-vertical-center2();

        border-bottom: 1px solid $c-default;
        border-left: 1px solid $c-default;
        transform: rotate(45deg);
    }
}

/* #m1300 */
@media (max-width: 1366px) {


}

/* #m1200 */
@media (max-width: 1220px) {

}

/* #m1100 */
@media (max-width: 1100px) {

    .card {

        width: 30%;
        margin-left: 3.2%;
    }

    .card {

        &--big {
            width: 100%;
        }
    }
}

/* #m900 */
@media (max-width: 989px) {

}

/* #m800 */
@media (max-width: 859px) {

    .card {

        &--big {
            flex-direction: column;
            height: auto;
            margin-top: 46px;
            margin-bottom: 55px;

        }

        &--big &__body, &--big &__image {
            width: 100% !important;

            margin-left: auto;
            margin-right: auto;
        }

        &--big &__image {
            height: auto !important;
        }

        &--big &__image img {
            height: auto !important;
        }

        &--big &__product-info {
            margin-bottom: 20px;
        }

        &--big &__title {
            margin-bottom: 16px;
            font-size: 18px;
        }

        &--big &__text {
            margin-bottom: 16px;
            font-size: 15px;
            height: 60px;
        }

        &--big &__price {
            font-size: 18px;
        }

        &--big &__count {
            font-size: 15px;
        }

        &--big &__button-wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &--big &__button {
            width: 48%;
            padding-left: 20px;
        }
    }

    .card {

        &__button-text {
            padding-left: 0;

        }

        &__title {
            font-size: 16px;
        }
    }
}

/* #m700 */
@media (max-width: 767px) {

    .card-wrapper {
        justify-content: space-between;
        margin-left: 0;
        margin-bottom: 20px;
    }

    .card {
        width: 49%;
        height: auto;
        margin-left: 0;
        margin-bottom: 5px;
        padding-bottom: 10px;

        &__image, img {
            width: 100%;
            height: 100%;
        }

        &__body {
            padding-left: 12px;
            padding-right: 12px;
            padding-bottom: 12px;
        }

        &__title {
            font-size: 15px;
        }
        &__text {
            font-size: 13px;
            height: 50px;
        }

        &__price {
            font-size: 15px;
        }

        &__count {
            font-size: 13px;
        }

        &__button {
            margin-left: 12px;
            margin-right: 12px;
        }

        &__product-info {
            padding-left: 12px;
            padding-right: 12px;
        }


    }

    .card {

        &--big {
            width: 100%;
            margin-bottom: 57px;
        }


    }
}

/* #m600 */
@media (max-width: 620px) {


    .card {


        &__button {
            //     padding-left: 20px;
        }

    }

}

/* #m500 */
@media (max-width: 559px) {

}

/* #m400 */
@media (max-width: 479px) {

}

/* #m400 */
@media (max-width: 439px) {

}

/* #m300 */
@media (max-width: 375px) {
    .card{
        &--big &__text {
            height: 50px;
        }
        .card__body{
            .card__title{
                font-size: 12px;
                margin-bottom: 6px;
            }
            .card__text{
                font-size: 11px;
            }
        }
        .card__product-info{
            white-space: nowrap;
            .card__price{
                font-size: 12px;
            }
            .card__count{
                font-size: 10px;
            }
        }
    }
}
